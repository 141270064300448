import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import errorReducer from './slices/error';
import notificationReducer from './slices/notification';

const store = configureStore({
  reducer: {
    auth: authReducer,
    notifications: notificationReducer,
    errors: errorReducer
  },
  devTools: true
});

export default store;
