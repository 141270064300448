import PropTypes from 'prop-types';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MapRouting from './MapRouting';

MapView.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      popupText: PropTypes.string,
      coordinates: PropTypes.arrayOf(PropTypes.number),
      pinData: {
        id: PropTypes.string,
        type: PropTypes.string
      }
    })
  ),
  editMode: PropTypes.bool,
  onPinChange: PropTypes.func,
  routing: PropTypes.bool
};

function getCenter(arr) {
  const added = arr.reduce(
    (acc, curr) => {
      acc[0] += curr[0];
      acc[1] += curr[1];

      return acc;
    },
    [0, 0]
  );

  return added.map((coordinate) => coordinate / arr.length);
}

L.Marker.prototype.options.icon = L.icon({
  iconUrl: '/static/icons/map-marker.png'
});

export default function MapView({ height, width, positions, routing, editMode, onPinChange }) {
  return (
    <MapContainer
      style={{ height: height || '100%', width: width || '100%' }}
      center={
        routing
          ? getCenter(positions.map((position) => position.coordinates))
          : positions[0].coordinates
      }
      zoom={13}
      scrollWheelZoom
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
        id="mapbox/streets-v11"
        tileSize={512}
        zoomOffset={-1}
        accessToken="pk.eyJ1IjoianVkZ2Vnb2R3aW5zIiwiYSI6ImNrYmR0NGYyMTBmM2Qyd20yNWc4bmN5bzEifQ.uHhT8TDbUxBSJk9hODDpSw"
      />
      {routing ? (
        <MapRouting positions={positions} editMode={editMode} onPinChange={onPinChange} />
      ) : (
        <>
          {positions.map((position, index) => (
            <Marker key={index} position={position.coordinates}>
              <Popup>{position.popupText}</Popup>
            </Marker>
          ))}
        </>
      )}
    </MapContainer>
  );
}
