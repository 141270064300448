import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';

import { List, ListSubheader, Stack, Button } from '@mui/material';

import DraggableListItem from './DraggableListItem';
import { pinPropType } from './itemPropType';

const DraggableList = React.memo(
  ({ pins, onDragEnd, setDialogOpen, setDeleteDialogOpen, setToBeDeleted }) => {
    const { t } = useTranslation();
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <List
              subheader={
                <ListSubheader
                  component={Stack}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  id="nested-list-subheader"
                >
                  {t('locations')}
                  <Button onClick={() => setDialogOpen(true)}>{t('create-location')}</Button>
                </ListSubheader>
              }
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {pins.map((pin, index) => (
                <DraggableListItem
                  pin={pin}
                  index={index}
                  key={pin._id}
                  setDeleteDialogOpen={setDeleteDialogOpen}
                  setToBeDeleted={setToBeDeleted}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

DraggableList.propTypes = {
  pins: PropTypes.arrayOf(pinPropType).isRequired,
  onDragEnd: PropTypes.func,
  setDialogOpen: PropTypes.func,
  setDeleteDialogOpen: PropTypes.func,
  setToBeDeleted: PropTypes.func
};

export default DraggableList;
