import axios from 'axios';
import { apiErrorParser, commonSuccessRespFilter } from '../utils/responseHelpers';
import { getAccessToken } from '../utils/authHelpers';

export default class StatsService {
  static http() {
    console.log('API Url: ', process.env.REACT_APP_API_URL, process.env.REACT_APP_GOOGLE_API_KEY);
    return axios.create({
      baseURL: 'https://api.wesy.ch/stats',
      // baseURL: 'http://localhost:8080/stats',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      }
    });
  }

  static getRoutesPercentage() {
    return this.http()
      .get('/get-route-type-percentage')
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getRoutesPercentageForRegion(region) {
    return this.http()
      .get('/get-route-type-percentage-for-region', { params: { id: region } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getHighConc(type) {
    return this.http()
      .get(`/regions-with-highest-concentration/${type}`)
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getPinPercentageByPotential() {
    return this.http()
      .get('/pins-grouped-according-to-potential')
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getPinPercentageByPotentialForRegion(region) {
    return this.http()
      .get('/pins-grouped-according-to-potential-for-region', { params: { id: region } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getBranchVisits(year, branch) {
    return this.http()
      .get(`/branch-visit-count/${branch}`, { params: { year } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getCoordinates(type) {
    return this.http()
      .get(`/get-location-coordinates/${type}`)
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getWorkerRoutesCount() {
    return this.http()
      .get('/get-worker-routes-count')
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getWorkerVisitsData(year, page, limit) {
    return this.http()
      .get('/worker-visits', { params: { year, page, limit } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }
}
