import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Icon } from '@iconify/react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import plusFill from '@iconify/icons-eva/plus-fill';
import { sentenceCase } from 'change-case';
import {
  // DialogContent,
  // DialogContentText,
  // Dialog,
  // DialogTitle,
  // DialogActions,
  Grid,
  // Paper,
  Chip,
  Button,
  Box,
  // TextField,
  // IconButton,
  Container,
  Typography,
  CardHeader,
  Stack,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  List,
  ListSubheader,
  Divider,
  CircularProgress,
  useTheme
} from '@mui/material';
import Page from '../components/Page';
import RouteService from '../services/RouteService';
import Label from '../components/Label';
import getRouteFromType from '../utils/getRouteFromType';
import Notes from '../components/details/Notes';
import Photos from '../components/details/Photos';
import Visits from '../components/details/Visits';
import MapView from '../components/MapView';
import PinForm from '../components/routing/PinForm';
import getChangedValues from '../utils/getChangedValues';
import { useNotification } from '../contexts/notification-context';
import ImportantNote from '../components/details/ImportantNote';
import ChangeRoute from '../components/routing/ChangeRoute';

export default function PinDetails() {
  const { t } = useTranslation();
  const [details, setDetails] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [changeDialogOpen, setChangeDialogOpen] = useState(false);
  const params = useParams();
  const notification = useNotification();

  const setImages = (images) => {
    setDetails((prev) => ({
      ...prev,
      images
    }));
  };

  useEffect(() => {
    RouteService.getPinDetails(params.id).then(({ data: resData }) => {
      setDetails(resData.data);
    });
  }, [params.id]);

  return (
    <Page title="Pin Details | Wesy Dashboard">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {details && details.name}
          </Typography>
          <Stack direction="row" alignItems="center">
            <Button variant="contained" onClick={() => setUpdateDialogOpen(true)}>
              {t('update')}
            </Button>
            <Button sx={{ ml: 1 }} variant="contained" onClick={() => setChangeDialogOpen(true)}>
              {t('switch-route')}
            </Button>
          </Stack>
        </Stack>
        {details ? (
          <>
            <PinForm
              update
              title={t('update')}
              buttonText={t('update')}
              open={updateDialogOpen}
              handleClose={() => setUpdateDialogOpen(false)}
              forConstruction={details.pinType === 'construction'}
              initValues={_.omit({ ...details, region: details.region._id }, [
                'visits',
                'notes',
                'images',
                'route',
                'pinType'
              ])}
              onSubmit={(values, initialValues, actions) => {
                const changed = getChangedValues(values, initialValues);
                RouteService.updatePin(changed, details._id, details.pinType)
                  .then(({ data: resData }) => {
                    notification.notify(resData.message);
                    actions.setSubmitting(false);
                    setDetails((prev) => ({ ...prev, ...changed }));
                    setUpdateDialogOpen(false);
                  })
                  .catch((e) => {
                    actions.setSubmitting(false);
                    notification.notify(e);
                  });
              }}
            />
            <ChangeRoute
              open={changeDialogOpen}
              handleClose={() => setChangeDialogOpen(false)}
              region={details.region._id}
              pinType={details.pinType}
              pinId={details._id}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Card>
                    <CardContent>
                      <List>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: 'capitalize' }}
                            primary={details.name}
                            secondary={t('name')}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: 'capitalize' }}
                            primary={details.address || t('none')}
                            secondary={t('address')}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: 'capitalize' }}
                            primary={details.city || t('none')}
                            secondary={t('city')}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: 'capitalize' }}
                            primary={details.postalCode || t('none')}
                            secondary={t('postal-code')}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: 'capitalize' }}
                            primary={details.region.name}
                            secondary={t('region')}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: 'capitalize' }}
                            primary={details.company || t('none')}
                            secondary={t('company')}
                          />
                        </ListItem>
                        {details.constructionPhase && (
                          <ListItem>
                            <ListItemText
                              sx={{ textTransform: 'capitalize' }}
                              primary={
                                <Label
                                  variant="ghost"
                                  color={
                                    (details.constructionPhase === 'A' && 'error') ||
                                    (details.constructionPhase === 'B' && 'warning') ||
                                    'success'
                                  }
                                >
                                  {sentenceCase(details.constructionPhase)}
                                </Label>
                              }
                              secondary={t('constr-phase')}
                            />
                          </ListItem>
                        )}
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: 'capitalize' }}
                            secondary={t('potential')}
                            primary={
                              details.potential ? (
                                <Label
                                  variant="ghost"
                                  color={
                                    (details.potential === 'low' && 'error') ||
                                    (details.potential === 'medium' && 'warning') ||
                                    'success'
                                  }
                                >
                                  {sentenceCase(details.potential)}
                                </Label>
                              ) : (
                                t('none')
                              )
                            }
                          />
                        </ListItem>
                        {details.branches && (
                          <ListItem>
                            <ListItemText
                              sx={{ textTransform: 'capitalize' }}
                              primary={details.branches.join(', ')}
                              secondary={t('business')}
                            />
                          </ListItem>
                        )}
                        {details.startDate && (
                          <ListItem>
                            <ListItemText
                              sx={{ textTransform: 'capitalize' }}
                              primary={new Date(details.startDate).toLocaleDateString()}
                              secondary={t('start-date')}
                            />
                          </ListItem>
                        )}
                        {details.finishDate && (
                          <ListItem>
                            <ListItemText
                              sx={{ textTransform: 'capitalize' }}
                              primary={new Date(details.finishDate).toLocaleDateString()}
                              secondary={t('finish-date')}
                            />
                          </ListItem>
                        )}
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: 'capitalize' }}
                            primary={new Date(details.lastUpdated).toLocaleString()}
                            secondary={t('last-updated')}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: 'capitalize' }}
                            primary={
                              details.lastVisited
                                ? new Date(details.lastVisited).toLocaleDateString()
                                : 'No visits yet'
                            }
                            secondary={t('last-visited')}
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                  {/* {coordinatesExist && (
                  <Card>
                    <CardHeader title="Map" />
                    <CardContent>
                      <MapView
                        height="50vh"
                        positions={
                          details.__t !== 'ConstructionRoute'
                            ? [
                                {
                                  popupText: details.location.address,
                                  coordinates: details.location.coordinates
                                }
                              ]
                            : details.locations
                                .filter((loc) => loc.coordinates.length === 2)
                                .map((loc) => ({
                                  popupText: `${loc.name}, ${loc.address}`,
                                  coordinates: loc.coordinates
                                }))
                        }
                      />
                    </CardContent>
                  </Card>
                )} */}
                  <Photos setDetails={setDetails} setImages={setImages} itemData={details.images} />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <ImportantNote />
                  <Notes />
                  <Visits />
                </Stack>
              </Grid>
            </Grid>
          </>
        ) : (
          <CircularProgress />
        )}
      </Container>
    </Page>
  );
}
