import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import englishTranslations from './locales/en/translations.json';
import frenchTranslations from './locales/fr/translations.json';
import germanTranslations from './locales/de/translations.json';
import italianTranslations from './locales/it/translations.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: localStorage.getItem('default_lng') || 'en',
  resources: {
    en: {
      translations: englishTranslations
    },
    de: {
      translations: germanTranslations
    },
    fr: {
      translations: frenchTranslations
    },
    it: {
      translations: italianTranslations
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'es'];

export default i18n;
