import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import {
  Grid,
  Container,
  TextField,
  Stack,
  Typography,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  InputLabel,
  ListItem,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  List,
  ListItemText,
  DialogActions,
  Button,
  ListItemAvatar,
  Avatar,
  DialogContent,
  FormHelperText
} from '@mui/material';
import { Box } from '@mui/system';
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Error from '@mui/icons-material/Error';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'sentence-case';

import Add from '@mui/icons-material/Add';

import Page from '../Page';
import RouteService from '../../services/RouteService';
import UserForm from '../authentication/register/UserForm';
import { pushError } from '../../slices/error';
import { LocationForm } from './LocationForms';
import AuthService from '../../services/AuthService';
// import RouteService from 'src/services/RouteService';

export const baseRouteFields = [
  { name: 'name', label: 'Name of Route', comp: 'textfield', initialValues: '', type: 'text' },
  { name: 'lastVisited', label: 'Last Visited', comp: 'date' },
  { name: 'lastUpdated', label: 'Last Updated', comp: 'date' },
  { name: 'potential', label: 'Potential', comp: 'select', initialValues: '' },
  { name: 'company', label: 'Company', comp: 'textfield', initialValues: '' }
];

const BaseSchema = Yup.object().shape({
  name: Yup.string().required(),
  potential: Yup.string().required(),
  worker: Yup.string().required(),
  company: Yup.string().required()
});

const BranchesDialog = ({ onClose, open, arrayHelpers, values }) => {
  const branches = ['E', 'H', 'S', 'MG'];

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    if (values.includes(value)) return;
    arrayHelpers.push(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Add branch</DialogTitle>
      <List>
        {branches.map((branch, index) => (
          <ListItem key={index} button onClick={() => handleListItemClick(branch)}>
            {values.includes(branch) && (
              <ListItemAvatar>
                <Avatar>
                  <Icon color="#00f" name="eva:checkmark-outline" height={22} width={22} />
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText primary={branch} />
          </ListItem>
        ))}
      </List>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const WorkersList = ({ open, onClose, handleSelect }) => {
  const [loading, setLoading] = useState(false);
  const [workers, setWorkers] = useState(null);

  useEffect(() => {
    if (!open) return;
    AuthService.getWorkers().then(({ data }) => setWorkers(data.data));
  }, [open]);

  // const
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Select Worker</DialogTitle>
      <List>
        {workers ? (
          workers.map((worker, index) => (
            <ListItem component={Button} onClick={() => handleSelect(worker)} key={worker._id}>
              <ListItemText
                primary={`${worker.firstName} ${worker.lastName}`}
                secondary={worker.email}
              />
            </ListItem>
          ))
        ) : (
          <Error />
        )}
      </List>
    </Dialog>
  );
};

const ErrHighlight = ({ error, children }) => (
  <Box
    style={{
      borderBottom: Boolean(error) && '1px solid #f00',
      padding: Boolean(error) && 5
    }}
  >
    {children}
  </Box>
);

export default function RouteForm({
  headerText,
  type,
  schema,
  fields,
  enableReinitialize,
  submitButtonText,
  initialValues,
  onFormSubmit
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState({
    potential: [
      { value: 'low', label: 'Low' },
      { value: 'medium', label: 'Medium' },
      { value: 'high', label: 'High' }
    ],
    constructionPhase: [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'G', label: 'G' }
    ]
  });
  const [dlogOpen, setDlogOpen] = useState(false);
  const [workerFormOpen, setWorkerFormOpen] = useState(false);
  const [workerSelectOpen, setWorkerSelectOpen] = useState(false);
  const [workerDisplay, setWorkerDisplay] = useState('');

  const fetchRegions = () => {
    if (menuItems.regions && menuItems.regions.length > 0) return;
    RouteService.getAllRegions().then(({ data }) => {
      setMenuItems((prev) => ({
        ...prev,
        regions: data.data.map((dt) => ({ value: dt._id, label: dt.name }))
      }));
    });
  };

  useEffect(() => {
    if (!initialValues) return;

    if (initialValues.worker)
      AuthService.getWorkerFromId(initialValues.worker)
        .then(({ data: { data: worker } }) =>
          setWorkerDisplay(`${worker.email} - ${worker.firstName} ${worker.lastName}`)
        )
        .catch((e) => dispatch(pushError(e)));

    fetchRegions();
  }, [dispatch, initialValues]);

  const routeFields = fields ? [...baseRouteFields, ...fields] : baseRouteFields;

  const Schema = BaseSchema.shape(schema);

  // useEffect(() => {
  //   RouteService.getAllRoutes().then(({ data }) => {
  //     setMenuItems((prev) => ({
  //       ...prev,
  //       potential: data.data
  //     }));
  //   });
  // });

  console.log(onFormSubmit);

  const formik = useFormik({
    initialValues,
    enableReinitialize: Boolean(enableReinitialize),
    validationSchema: Schema,
    onSubmit: onFormSubmit
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Page title="Create route | Minimal-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
          <Typography variant="h4" gutterBottom>
            {headerText}
          </Typography>
        </Stack>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {routeFields.map((field, key) => (
                  <Grid item xs={12} md={6} key={key}>
                    {(field.comp === 'textfield' && (
                      <TextField
                        fullWidth
                        type={field.type || 'text'}
                        label={field.label}
                        {...getFieldProps(field.name)}
                        error={Boolean(touched[field.name] && errors[field.name])}
                        helperText={touched[field.name] && errors[field.name]}
                      />
                    )) ||
                      (field.comp === 'select' && (
                        <FormControl
                          fullWidth
                          error={Boolean(touched[field.name] && errors[field.name])}
                        >
                          <InputLabel id={`select-${field.name}-label`}>{field.label}</InputLabel>
                          <Select
                            fullWidth
                            labelId={`select-${field.name}-label`}
                            id={field.name}
                            label={field.label}
                            {...getFieldProps(field.name)}
                          >
                            {menuItems[field.name] ? (
                              menuItems[field.name].map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value={null}>Loading...</MenuItem>
                            )}
                          </Select>
                          <FormHelperText>
                            {touched[field.name] && errors[field.name]}
                          </FormHelperText>
                        </FormControl>
                      )) ||
                      (field.comp === 'date' && (
                        <DatePicker
                          format="dd/mm/yyyy"
                          label={field.label}
                          name={field.name}
                          value={values[field.name]}
                          id={field.name}
                          onChange={(value) => setFieldValue(field.name, value)}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                      ))}
                  </Grid>
                ))}
              </Grid>
              {type === 'construction' && (
                <Stack
                  style={{ marginTop: 10, marginBottom: 30 }}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography>Branches: </Typography>
                  <FieldArray
                    name="branches"
                    render={(arrayHelpers) => (
                      <>
                        <Stack
                          style={{ marginLeft: 20 }}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-evenly"
                        >
                          {values.branches && values.branches.length > 0 ? (
                            values.branches.map((branch, index) => (
                              <ListItem key={`branch.${index}`}>
                                <Chip
                                  name={`branch.${index}`}
                                  label={branch}
                                  onDelete={() => arrayHelpers.remove(index)}
                                />
                              </ListItem>
                            ))
                          ) : (
                            <Typography variant="caption">
                              <em>None selected</em>
                            </Typography>
                          )}
                        </Stack>
                        <BranchesDialog
                          open={dlogOpen}
                          onClose={() => setDlogOpen(false)}
                          arrayHelpers={arrayHelpers}
                          values={values.branches}
                        />
                      </>
                    )}
                  />
                  <IconButton onClick={() => setDlogOpen(true)} color="primary">
                    <Add />
                  </IconButton>
                </Stack>
              )}
              <LocationForm
                type={type}
                setFieldValue={setFieldValue}
                initialValue={initialValues.locations}
                values={values}
                getFieldProps={getFieldProps}
                touched={touched}
                errors={errors}
                menuItems={menuItems}
                fetchRegions={fetchRegions}
              />
              <Container disableGutters sx={{ mt: 3 }}>
                <ErrHighlight error={errors.worker}>
                  <Stack
                    spacing={2}
                    sx={{
                      mb: 1
                    }}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h5">Worker</Typography>
                    {workerDisplay && (
                      <Chip
                        name="worker"
                        label={workerDisplay}
                        onDelete={() => {
                          setWorkerDisplay('');
                          setFieldValue('worker', '');
                        }}
                      />
                    )}
                  </Stack>
                </ErrHighlight>
                {!workerDisplay && (
                  <Stack direction="row" spacing={2} justifyContent="flex-start">
                    <Button onClick={() => setWorkerFormOpen(true)}>Create new worker</Button>
                    <Button onClick={() => setWorkerSelectOpen(true)}>Select from workers</Button>
                  </Stack>
                )}

                <WorkersList
                  open={workerSelectOpen}
                  onClose={() => setWorkerSelectOpen(false)}
                  handleSelect={(worker) => {
                    setFieldValue('worker', worker._id);
                    setWorkerDisplay(`${worker.email} - ${worker.firstName} ${worker.lastName}`);
                    setWorkerSelectOpen(false);
                  }}
                />
                <Dialog open={workerFormOpen} onClose={() => setWorkerFormOpen(false)}>
                  <DialogTitle>Create Worker</DialogTitle>
                  <DialogContent>
                    <UserForm
                      marginTop
                      type="worker"
                      onFormSubmit={(values, setSubmitting) => {
                        AuthService.createWorker(values)
                          .then(
                            ({
                              data: {
                                data: { user }
                              }
                            }) => {
                              setSubmitting(false);
                              setFieldValue('worker', user._id);
                              setWorkerFormOpen(false);
                              setWorkerDisplay(
                                `${user.email} - ${user.firstName} ${user.lastName}`
                              );
                            }
                          )
                          .catch((e) => {
                            setSubmitting(false);
                            dispatch(pushError(e));
                          });
                      }}
                    />
                  </DialogContent>
                </Dialog>
              </Container>

              <LoadingButton
                sx={{ mt: 3 }}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {submitButtonText || 'Create route'}
              </LoadingButton>
            </Form>
          </FormikProvider>
        </LocalizationProvider>
      </Container>
    </Page>
  );
}
