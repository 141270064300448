import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FetchDataPieChart from '../../charts/FetchDataPieChart';
import StatsService from '../../../services/StatsService';

export default function AppRoutesPercentage() {
  return (
    <FetchDataPieChart
      title={useTranslation().t('type-chart')}
      loadData={(setChartData) => {
        StatsService.getRoutesPercentage()
          .then(({ data: { data } }) => {
            setChartData(data);
          })
          .catch(console.log);
      }}
    />
  );
}
