import axios from 'axios';
import { apiErrorParser, commonSuccessRespFilter } from '../utils/responseHelpers';
import { getAccessToken } from '../utils/authHelpers';

export default class ScheduleService {
  static http() {
    return axios.create({
      baseURL: 'https://api.wesy.ch/schedule',
      // baseURL: 'http://localhost:8080/schedule',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      }
    });
  }

  static createSchedule(data, token) {
    return this.http()
      .post('/new', data, {
        headers: {
          'x-registration-token': token
        }
      })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static createScheduleForUser(data, userId) {
    return this.http()
      .post('/create-schedule-for-user', data, { params: { userId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static createBroadcastSchedule(scheduleData) {
    return this.http()
      .post('/broadcast', scheduleData)
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getSchedules() {
    return this.http().get('/get-schedules').then(commonSuccessRespFilter).catch(apiErrorParser);
  }

  static getSchedulesForUser(userId) {
    return this.http()
      .get('/get-schedules-for-user', { params: { userId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getExpiredNotifications(page, limit) {
    return this.http()
      .get('/expired-notifications', { params: { page, limit } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static updateSchedule(id, data, token) {
    return this.http()
      .patch('/update', data, {
        params: { id },
        headers: {
          'x-registration-token': token
        }
      })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static updateScheduleForUser(id, userId, data) {
    return this.http()
      .patch('/update-schedule-for-user', data, { params: { id, userId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static deleteSchedule(id) {
    return this.http()
      .delete('/delete-schedule', { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static deleteScheduleForUser(id, userId) {
    return this.http()
      .delete('/delete-schedule-for-user', { params: { id, userId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }
}
