import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
// import type { RootState } from 'store'
import { login, signup, updateProfile } from './actions';

function isActionWithSuccessAuthDataPayload(action) {
  return (
    typeof action.payload === 'object' &&
    action.payload.success &&
    action.payload.data &&
    action.payload.data.user &&
    action.payload.data.token &&
    action.payload.data.tokenExpiresOn
  );
}

const authData = localStorage.getItem('auth_data');
const user = authData && JSON.parse(authData).user;

const initialState = user
  ? { loggedIn: true, user, loggingIn: false }
  : { loggedIn: false, user: null, loggingIn: false };

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem('auth_data');
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      console.log(action.type);
      state.user = { ...state.user, ...action.payload.data };
    });

    // builder.addCase(login.pending, (state, action) => {
    //   state.loggingIn = true;
    // })

    // builder.addCase(login.fulfilled, saveAuthData)
    // builder.addCase(signup.fulfilled, saveAuthData);

    builder.addMatcher(isActionWithSuccessAuthDataPayload, (state, action) => {
      state.user = action.payload.data.user;
      state.loggedIn = true;
    });

    //  errors reducer captures all errors;
    // builder.addCase(login.rejected, (state, action) => {
    //   console.log('action error: ', action.error);
    //   console.log('action: ', action);
    // })
  }
});

export default authSlice.reducer;

export const { logout } = authSlice.actions;
