import faker from 'faker';
import PropTypes from 'prop-types';
import { onMessage, getMessaging } from 'firebase/messaging';
import { noCase } from 'change-case';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { set, sub, formatDistanceToNow } from 'date-fns';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import { alpha } from '@mui/material/styles';
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import ScheduleService from '../../services/ScheduleService';

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.description ? noCase(notification.description) : ''}
      </Typography>
    </Typography>
  );

  if (notification.type === 'schedule') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_schedule.svg" />,
      title
    };
  }

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
      title
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
      title
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  return {
    avatar: <img alt={notification.title} src={notification.avatar} />,
    title
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      to="#"
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Stack spacing={1}>
            {notification.type === 'schedule' && (
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.disabled'
                }}
              >
                Start: {notification.createdAt.toLocaleTimeString()} End:{' '}
                {notification.endDate.toLocaleTimeString()}
              </Typography>
            )}
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled'
              }}
            >
              <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
              {formatDistanceToNow(notification.createdAt)}
            </Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

function parseNotification(notification, unRead) {
  console.log('IN PARSE NOTIF (ID) ------ :', notification.id);
  return {
    id: notification._id,
    title: notification.title,
    description: notification.notes,
    createdAt: new Date(notification.startDate),
    type: 'schedule',
    isUnRead: Boolean(unRead),
    endDate: new Date(notification.endDate)
  };
}

export default function NotificationsPopover() {
  const { t } = useTranslation();
  const messaging = getMessaging();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;
  const [expiredNotifications, setExpiredNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  onMessage(messaging, (payload) => {
    console.log('Message received in notif. ', payload);
    const { id, title, notes, startDate, endDate } = payload.data;
    setNotifications((prev) => [
      {
        id,
        title: `Schedule: ${title}`,
        description: notes,
        createdAt: new Date(startDate),
        type: 'schedule',
        isUnRead: true,
        endDate: new Date(endDate)
      },
      ...prev
    ]);

    setOpen(true);
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false
      }))
    );
  };

  const setNewPage = () => {
    if (!hasMore) return;
    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    setLoading(true);
    ScheduleService.getExpiredNotifications(page, 3)
      .then(({ data: resData }) => {
        setLoading(false);
        setExpiredNotifications((prev) => [...resData.data, ...prev]);
        setLoading(false);
        setHasMore(Boolean(resData.next));
      })
      .catch(console.log);
  }, [page]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ textTransform: 'capitalize' }} variant="subtitle1">
              {t('notifications')}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('read-msgs', { totalUnRead })}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                {t('new')}
              </ListSubheader>
            }
          >
            {notifications.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                {t('before')}
              </ListSubheader>
            }
          >
            {expiredNotifications
              .map((n) => parseNotification(n, false))
              .map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
          </List>
          {hasMore && (
            <Box sx={{ p: 1 }}>
              <LoadingButton
                loading={loading}
                onClick={setNewPage}
                fullWidth
                disableRipple
                component={RouterLink}
                to="#"
              >
                Load more
              </LoadingButton>
            </Box>
          )}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
