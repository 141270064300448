import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Box, Tabs, Tab, Typography, Stack, Container } from '@mui/material';

import Page from '../components/Page';
import Profile from '../components/settings/Profile';
import UpdatePassword from '../components/settings/UpdatePassword';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function Settings() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Page title={t('settings')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('settings')}
          </Typography>
        </Stack>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
              <Tab label={t('profile')} {...a11yProps(0)} />
              <Tab label={t('password')} {...a11yProps(1)} />
              {/* <Tab label="Test" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Profile />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <UpdatePassword />
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
}
