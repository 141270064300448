import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Card, CardHeader, CardContent, Stack, CircularProgress } from '@mui/material';
import MapView from '../../MapView';
import StatsService from '../../../services/StatsService';

export default function AppRouteCoordinates({ type, title }) {
  const { t } = useTranslation();
  const [coordinatesData, setCoordinatesData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    StatsService.getCoordinates(type)
      .then(({ data: { data } }) => {
        console.log('data: ', data);
        setCoordinatesData(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        {loading ? (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {coordinatesData.length > 0 ? (
              <MapView
                height="50vh"
                positions={coordinatesData.map((dt) => ({
                  popupText: `Name: ${dt.name}, Address: ${dt.address}`,
                  coordinates: dt.coordinates
                }))}
              />
            ) : (
              <Typography variant="subtitle1" color="textSecondary" textAlign="center">
                {t('no-loc-data')}
              </Typography>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
