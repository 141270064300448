import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export const baseConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'planning',
    path: '/dashboard/planning',
    icon: getIcon('bytesize:book')
  },
  {
    title: 'routes',
    path: '/dashboard/routes/all',
    icon: getIcon('gis:route')
  }
];

export const adminConfig = [
  ...baseConfig,
  {
    title: 'construction',
    path: '/dashboard/routes/construction',
    icon: getIcon('emojione-monotone:building-construction')
  },
  {
    title: 'store',
    path: '/dashboard/routes/store',
    icon: getIcon('clarity:store-line')
  },
  {
    title: 'industry',
    path: '/dashboard/routes/industry',
    icon: getIcon('iconoir:industry')
  },
  {
    title: 'workers',
    path: '/dashboard/users/workers',
    icon: getIcon('carbon:user')
  }
];

export const superAdminConfig = [
  ...adminConfig,
  {
    title: 'admins',
    path: '/dashboard/users/admins',
    icon: getIcon('carbon:user-certification')
  },
  {
    title: 'regions',
    path: '/dashboard/regions',
    icon: getIcon('emojione-monotone:cityscape')
  }
];
