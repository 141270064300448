import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box component="img" src="/static/logo_only.png" sx={{ width: '15%', height: '15%', ...sx }} />
  );
}
