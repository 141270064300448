import { useState } from 'react';
import {
  Grid,
  TextField,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Button,
  FormHelperText,
  Box,
  Chip,
  Divider
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FieldArray } from 'formik';
import Clear from '@mui/icons-material/Clear';
import { useNotification } from '../../contexts/notification-context';
import { isSuperAdmin } from '../../utils/authHelpers';
import { getLocationInitVals } from '../../utils/routeUtils';

const parseLocationCheckers = (type, errors, touched, index) => (prop) =>
  type === 'construction'
    ? touched.locations &&
      errors.locations &&
      touched.locations[index] &&
      errors.locations[index] &&
      touched.locations[index][prop] &&
      errors.locations[index][prop]
    : touched.location &&
      errors.location &&
      touched.location &&
      touched.location[prop] &&
      errors.location[prop];

const LocationFormBase = ({
  type,
  objRef,
  getFieldProps,
  setFieldValue,
  touched,
  errors,
  menuItems,
  index,
  fetchRegions
}) => {
  const checkers = parseLocationCheckers(type, errors, touched, index);
  const isConstructionMajorRoute = type === 'construction' && index === 0;
  const [isGettingLoc, setIsGettingLoc] = useState(false);
  const notification = useNotification();

  console.log('props: ', getFieldProps(`${objRef}.coordinates`));
  const coordinateValues = getFieldProps(`${objRef}.coordinates`).value;
  return (
    <>
      <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        {coordinateValues && coordinateValues.length === 2 ? (
          <Chip
            name="coordinates"
            onClick={() => console.log('chip clicked')}
            label={`Coordinates: ${coordinateValues.join(', ')}`}
            onDelete={() => {
              setFieldValue(`${objRef}.coordinates`, undefined);
            }}
          />
        ) : (
          <LoadingButton
            loading={isGettingLoc}
            onClick={() => {
              if ('geolocation' in navigator) {
                setIsGettingLoc(true);
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    console.log(position);
                    console.log(position.coords.latitude, position.coords.longitude);

                    setFieldValue(`${objRef}.coordinates.0`, position.coords.latitude);
                    setFieldValue(`${objRef}.coordinates.1`, position.coords.longitude);
                    setIsGettingLoc(false);
                  },
                  (e) => {
                    setIsGettingLoc(false);
                    notification.notify(e);
                  },
                  { enableHighAccuracy: true }
                );
              } else {
                alert('Geolocation is not available.');
              }
            }}
          >
            Use Device location
          </LoadingButton>
        )}
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            {...getFieldProps(`${objRef}.name`)}
            label="Name"
            error={Boolean(checkers('name'))}
            helperText={checkers('name')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            {...getFieldProps(`${objRef}.address`)}
            label="Address"
            error={Boolean(checkers('address'))}
            helperText={checkers('address')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            {...getFieldProps(`${objRef}.postalCode`)}
            label="Postal code"
            error={Boolean(checkers('postalCode'))}
            helperText={checkers('postalCode')}
          />
        </Grid>
        {(isSuperAdmin() || type === 'construction') && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              error={Boolean(checkers('region'))}
              disabled={!isSuperAdmin() && isConstructionMajorRoute}
            >
              <InputLabel id="select-region">Region</InputLabel>
              <Select
                onOpen={fetchRegions}
                fullWidth
                labelId="select-region"
                id="region"
                label="Region"
                {...getFieldProps(`${objRef}.region`)}
              >
                {menuItems.regions ? (
                  menuItems.regions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={null}>Loading...</MenuItem>
                )}
              </Select>
              <FormHelperText>
                {isConstructionMajorRoute ? "This is the major route's region" : checkers('region')}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export const LocationForm = ({
  type,
  initialValue,
  values,
  getFieldProps,
  setFieldValue,
  touched,
  errors,
  menuItems,
  fetchRegions
}) => (
  <Box sx={{ mt: 3 }}>
    <Typography variant="h5" gutterBottom>
      Add Location Pin{type === 'construction' && 's'}
    </Typography>
    {type === 'construction' ? (
      <FieldArray
        name="locations"
        render={(arrayHelpers) =>
          values.locations &&
          values.locations.length > 0 &&
          values.locations.map((locationItem, index) => (
            <Box key={index}>
              <Box sx={index !== 0 ? { mt: 3 } : {}} key={`location-${index}`}>
                <Stack
                  direction="row"
                  sx={{ mb: 1 }}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" justifyContent="flex-start">
                    <Typography variant="subtitle1">Pin {index + 1}</Typography>
                    {index !== 0 && (
                      <IconButton onClick={() => arrayHelpers.remove(index)}>
                        <Clear />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>
                <LocationFormBase
                  setFieldValue={setFieldValue}
                  type="construction"
                  objRef={`locations.${index}`}
                  getFieldProps={getFieldProps}
                  touched={touched}
                  errors={errors}
                  menuItems={menuItems}
                  fetchRegions={fetchRegions}
                  index={index}
                />
              </Box>
              {index === values.locations.length - 1 && (
                <Box sx={{ mt: 2 }}>
                  <Button
                    color="primary"
                    onClick={() => arrayHelpers.push({ ...getLocationInitVals(), major: false })}
                  >
                    Add pin
                  </Button>
                </Box>
              )}
            </Box>
          ))
        }
      />
    ) : (
      <Box sx={{ mt: 3 }}>
        <LocationFormBase
          setFieldValue={setFieldValue}
          type={type}
          objRef="location"
          getFieldProps={getFieldProps}
          touched={touched}
          errors={errors}
          menuItems={menuItems}
          fetchRegions={fetchRegions}
        />
      </Box>
    )}
  </Box>
);
