import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import cancel from '@iconify/icons-ic/cancel';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

TableListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  deleteItems: PropTypes.func,
  clearFilter: PropTypes.func,
  clear: PropTypes.bool
};

export default function TableListToolbar({
  numSelected,
  filterName,
  onFilterName,
  deleteItems,
  clearFilter,
  clear
}) {
  const { t } = useTranslation();
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder={`${t('search-route')}...`}
          // startAdornment={
          //   <InputAdornment position="start">
          //     <Box component={Icon} icon={cancel} sx={{ color: 'text.disabled' }} />
          //   </InputAdornment>
          // }
          endAdornment={
            clear && (
              <InputAdornment>
                <IconButton>
                  <Box
                    component={Icon}
                    icon={cancel}
                    sx={{ color: 'text.disabled' }}
                    onClick={clearFilter}
                  />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={deleteItems}>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Icon icon={roundFilterList} />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
