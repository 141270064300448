import {
  Container,
  Grid,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'change-case';

import Page from '../Page';
import RouteService from '../../services/RouteService';
import { useNotification } from '../../contexts/notification-context';
import { isSuperAdmin } from '../../utils/authHelpers';

export default function RouteForm({ open, handleClose, type }) {
  const notification = useNotification();
  const { t } = useTranslation();
  const [regions, setRegions] = useState([]);
  const navigate = useNavigate();

  const Schema = Yup.object({
    name: Yup.string().required(t('generic-req'))
  });

  const initialValues = {
    name: ''
  };

  console.log('issup', isSuperAdmin());
  const formik = useFormik({
    initialValues: isSuperAdmin() ? { ...initialValues, createdBy: '' } : initialValues,
    validationSchema: isSuperAdmin()
      ? Schema.shape({ createdBy: Yup.string().required(t('generic-req')) })
      : Schema,
    onSubmit: (values, { setSubmitting }) => {
      RouteService.createRoute(values, type)
        .then(({ data: resData }) => {
          setSubmitting(false);
          notification.notify(resData.message);
          navigate(`/dashboard/routes/details/${resData.data._id}`, { replace: true });
        })
        .catch((e) => {
          setSubmitting(false);
          notification.notify(e);
        });
    }
  });

  console.log('init: ', formik.initialValues);

  const fetchRegions = () =>
    RouteService.getAllRegions().then(({ data }) => {
      setRegions(data.data.map((dt) => ({ value: dt._id, label: dt.name })));
    });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('create-route')}</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={isSuperAdmin() ? 6 : 12}>
                <TextField
                  label={t('name-route')}
                  fullWidth
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              {Object.prototype.hasOwnProperty.call(values, 'createdBy') && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={Boolean(touched.createdBy && errors.createdBy)}>
                    <InputLabel id="select-createdby">{t('region')}</InputLabel>
                    <Select
                      onOpen={fetchRegions}
                      fullWidth
                      labelId="select-createdby"
                      id="createdby"
                      label={t('region')}
                      {...getFieldProps('createdBy')}
                    >
                      {regions.length > 0 ? (
                        regions.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value={null}>Loading...</MenuItem>
                      )}
                    </Select>
                    <FormHelperText>{touched.createdBy && errors.createdBy}</FormHelperText>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <LoadingButton loading={isSubmitting} type="submit">
              {t('create')}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
