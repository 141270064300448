import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine';
import { useMap } from 'react-leaflet';

MapRouting.propTypes = {
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      popupText: PropTypes.string,
      coordinates: PropTypes.arrayOf(PropTypes.number),
      pinData: {
        id: PropTypes.string,
        type: PropTypes.string
      }
    })
  ),
  onPinChange: PropTypes.func,
  editMode: PropTypes.bool
};

export default function MapRouting({ positions, editMode, onPinChange }) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const waypoints = positions
      .map((position) => position.coordinates)
      .map((coordinateData) => L.latLng(coordinateData[0], coordinateData[1]));

    const routingControl = L.Routing.control({
      waypoints,
      routeWhileDragging: editMode || false,
      addWaypoints: false,
      router: L.routing.mapbox(
        'pk.eyJ1IjoianVkZ2Vnb2R3aW5zIiwiYSI6ImNrYmR0NGYyMTBmM2Qyd20yNWc4bmN5bzEifQ.uHhT8TDbUxBSJk9hODDpSw'
      ),
      createMarker: (i, wp, nWps) =>
        L.marker(wp.latLng, { draggable: editMode })
          .bindPopup(positions[i].popupText)
          .on('dragend', (e) => {
            if (!editMode) return;
            // setChanges({ _id, pinType, coordinates: Object.values(e.target._latlng) });
            const { _id, pinType } = positions[i].pinData;
            onPinChange({ _id, pinType, coordinates: Object.values(e.target._latlng) });
          })
    }).addTo(map);

    routingControl.hide();

    return () => map.removeControl(routingControl);
  }, [map, positions, editMode, onPinChange]);

  return <></>;
}
