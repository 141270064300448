import { useSelector } from 'react-redux';
import { useState, useEffect, forwardRef } from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNotification } from '../contexts/notification-context';

const AlertBase = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function Alert() {
  const [open, setOpen] = useState(false);
  const notification = useNotification();

  useEffect(() => {
    if (notification.latestNotification) setOpen(true);
  }, [notification.latestNotification]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    notification.latestNotification && (
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <AlertBase
          onClose={handleClose}
          severity={notification.latestNotification.type}
          sx={{ width: '100%' }}
        >
          {notification.latestNotification.message}
        </AlertBase>
      </Snackbar>
    )
  );
}
