import { useEffect, useState } from 'react';
import { PieChart } from '.';

export default function AppRoutesPercentage({ loadData, title }) {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    loadData(setChartData);
  }, [loadData]);

  return (
    <PieChart
      title={title}
      data={chartData.map((dt) => dt.count)}
      labels={chartData.map((dt) => dt.label)}
    />
  );
}
