export const mapLangToMuiLocale = {
  en: 'enUS',
  fr: 'frFR',
  de: 'deDE'
};

export const mapLangToDateFnsLocale = {
  en: 'enGB',
  fr: 'fr',
  de: 'de',
  it: 'it'
};
