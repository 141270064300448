import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { serializeError } from 'serialize-error';

import { pushNotification } from '../slices/notification';
import { pushError } from '../slices/error';

const notificationContext = createContext();

export const useNotification = () => useContext(notificationContext);

const useProvideNotification = () => {
  const dispatch = useDispatch();

  const notify = (payload, type) => {
    if (payload instanceof Error) {
      dispatch(pushNotification({ message: payload.message, type: 'error' }));
    } else if (typeof payload === 'string') {
      dispatch(pushNotification({ message: payload, type: type || 'success' }));
    }
  };

  const latestNotification = useSelector(({ notifications }) => {
    if (notifications.length <= 0) return null;
    return notifications[notifications.length - 1];
  });

  const allNotifications = useSelector(({ notifications }) => notifications);

  return { notify, latestNotification, allNotifications };
};

export function NotificationProvider({ children }) {
  const notification = useProvideNotification();

  return (
    <notificationContext.Provider value={notification}>{children}</notificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};
