import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Card,
  TablePagination,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  Stack
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import StatsService from '../../../services/StatsService';
import { getYears, months } from '../../../utils/date';

export default function WorkerVisitsTable({ type }) {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());

  const years = getYears(2000);

  // const { t } = useTranslation();

  const handleChangeRowsPerPage = (event) => {
    setData([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
    setData([]);
    setPage(0);
  };

  useEffect(() => {
    // if (data.length !== 0 && data.length >= count) return;
    StatsService.getWorkerVisitsData(year, page + 1, rowsPerPage)
      .then(({ data: resData }) => {
        setData((prev) => [...prev, ...resData.data]);
        setCount(resData.count);
      })
      .catch(console.log);
  }, [year, page, rowsPerPage]);

  return (
    <Card>
      <CardHeader
        title={
          (type === 'visits' && t('worker-visits')) ||
          (type === 'newMembers' && t('new-members')) ||
          ''
        }
        action={
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Year</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={year}
              label="Year"
              onChange={handleYearChange}
            >
              {years.map((yr) => (
                <MenuItem key={yr} value={yr}>
                  {yr}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      />
      <CardContent>
        <Scrollbar>
          <TableContainer>
            <Table>
              {data.length > 0 ? (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell key="name">Name</TableCell>
                      {months.map((month) => (
                        <TableCell key={month} align="right">
                          {month}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((dt) => (
                      <TableRow
                        key={dt._id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell key="name" component="th" scope="row">
                          <Typography color="inherit" variant="subtitle2">
                            {dt.firstName} {dt.lastName}
                          </Typography>
                        </TableCell>
                        {dt.monthData.map((monthDt) => (
                          <TableCell key={monthDt.month} align="center">
                            {monthDt[type]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Stack justifyContent="center" alignItems="center">
                        <CircularProgress />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[3, 6, 9, 12]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
}

WorkerVisitsTable.propTypes = {
  type: PropTypes.oneOf('visits', 'newMembers')
};
