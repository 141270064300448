import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';

// material
import { Card, CardHeader, Stack, CircularProgress, Box } from '@mui/material';
//
import { BaseOptionChart } from '.';

export default function BarChart({
  barOptions,
  horizontal,
  title,
  xaxis,
  data,
  seriesName,
  cardHeaderAction
}) {
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: 1 },
    plotOptions: {
      bar: { horizontal: Boolean(horizontal), columnWidth: '11%', borderRadius: 2, ...barOptions }
    },
    fill: { type: ['solid', 'gradient', 'solid'] },
    xaxis: {
      categories: xaxis
    }
    // tooltip: {
    //   shared: true,
    //   intersect: false,
    //   y: {
    //     formatter: (y) => {
    //       if (typeof y !== 'undefined') {
    //         return `${y.toFixed(0)} visits`;
    //       }
    //       return y;
    //     }
    //   }
    // }
  });

  return (
    <Card>
      <CardHeader title={title} action={cardHeaderAction} />
      {data.length > 0 ? (
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <ReactApexChart
            type="bar"
            series={[{ name: seriesName, data }]}
            options={chartOptions}
            height={364}
          />
        </Box>
      ) : (
        <Stack justifyContent="center" alignItems="center" sx={{ p: 2 }}>
          <CircularProgress />
        </Stack>
      )}
    </Card>
  );
}
