import axios from 'axios';
import { apiErrorParser, commonSuccessRespFilter } from '../utils/responseHelpers';
import { getAccessToken } from '../utils/authHelpers';

console.log('ENV: ', process.env.NODE_ENV);

export default class SearchService {
  static http() {
    console.log('API Url: ', process.env.REACT_APP_API_URL, process.env.REACT_APP_GOOGLE_API_KEY);
    return axios.create({
      baseURL: 'https://api.wesy.ch/search',
      // baseURL: 'http://localhost:8080/search',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      }
    });
  }

  static searchPins(keyword, type, page, limit) {
    return this.http()
      .get('/pins', { params: { keyword, type, page, limit } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static searchRoutes(keyword, page, limit) {
    return this.http()
      .get('/routes', { params: { keyword, page, limit } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }
}
