import { createAsyncThunk } from '@reduxjs/toolkit';
import { editAuthData, saveAuthData } from '../../utils/authHelpers';
import AuthService from '../../services/AuthService';

export const login = createAsyncThunk('users/login', async (loginDetails, thunkAPI) => {
  const response = await AuthService.login(loginDetails);

  saveAuthData(response.data.data);
  return response.data;
});

export const signup = createAsyncThunk('users/signup', async (signupDetails, thunkAPI) => {
  const response = await AuthService.signup(signupDetails);

  saveAuthData(response.data.data);

  return response.data;
});

export const updateProfile = createAsyncThunk('users/update-profile', async (details, thunkAPI) => {
  const response = await AuthService.updateProfile(details);

  editAuthData((prev) => ({ ...prev, user: { ...prev.user, ...response.data.data } }));

  return response.data;
});
