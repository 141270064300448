import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { Link } from 'react-router-dom';
import { pinPropType } from './itemPropType';

DraggableListItem.propTypes = {
  pin: pinPropType,
  index: PropTypes.number.isRequired,
  setDeleteDialogOpen: PropTypes.func,
  setToBeDeleted: PropTypes.func
};

export default function DraggableListItem({ pin, index, setDeleteDialogOpen, setToBeDeleted }) {
  const { t } = useTranslation();

  return (
    <Draggable draggableId={pin._id} index={index}>
      {(provided) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => {
                console.log('Delete clicked');
                setDeleteDialogOpen(true);
                setToBeDeleted(pin._id);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          }
          disablePadding
        >
          <ListItemButton component={Link} to={`/dashboard/routes/pin/details/${pin._id}`}>
            <ListItemIcon>
              <LocationOnIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={pin.name || t('none')}
              secondary={`${pin.address || t('none')}, ${pin.city || t('none')}`}
            />
          </ListItemButton>
        </ListItem>
      )}
    </Draggable>
  );
}
