import PropTypes from 'prop-types';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Autocomplete, TextField } from '@mui/material';
import { mapsAPIKey } from '../utils/routeUtils';
// import { useEffect, useState } from 'react';

export default function PlaceAutocomplete({
  onCoordinatesValue,
  label,
  onInputChange,
  onChange,
  touched,
  error,
  value
}) {
  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: mapsAPIKey
  });

  // const coordinates = useState([]);

  // useEffect(() => {
  //   onCoordinatesValue(coordinates);
  // }, [coordinates, onCoordinatesValue]);

  // console.log('Predictions: ', placePredictions);

  return (
    <Autocomplete
      freeSolo
      id="auto-complete"
      disableClearable
      value={value}
      options={placePredictions.map((option) => option.description)}
      onChange={(event, value) => {
        const place = placePredictions.find((p) => p.description === value);
        placesService.getDetails({ placeId: place.place_id }, (details) => {
          onCoordinatesValue([details.geometry.location.lat(), details.geometry.location.lng()]);
        });
        onChange(value);
      }}
      onInputChange={(event, value) => {
        getPlacePredictions({ input: value });
        onInputChange(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={Boolean(touched && error)}
          helperText={touched && error}
          label={label || 'Location'}
          InputProps={{
            ...params.InputProps,
            type: 'search'
          }}
        />
      )}
    />
  );
}

PlaceAutocomplete.propTypes = {
  onCoordinatesValue: PropTypes.func,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  error: PropTypes.any,
  touched: PropTypes.any
};
