import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { vapidConfig } from './utils/firebaseUtils';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBQKDo2hsxw2kV-tQijUI8DuM27cGkBtIk',
  authDomain: 'company-dashboard-7154b.firebaseapp.com',
  projectId: 'company-dashboard-7154b',
  storageBucket: 'company-dashboard-7154b.appspot.com',
  messagingSenderId: '456627788531',
  appId: '1:456627788531:web:b2fc84297cf02f3d3fb586'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

// messaging.getToken(vapidConfig);

// getToken(messaging, { ...vapidConfig })
//   .then((currentToken) => {
//     if (currentToken) {
//       console.log('current token for client: ', currentToken);

//       // Track the token -> client mapping, by sending to backend server
//       // show on the UI that permission is secured
//     } else {
//       console.log('No registration token available. Request permission to generate one.');

//       // shows on the UI that permission is required
//     }
//   })
//   .catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // catch error while creating client token
//   });

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
});
