import { filter, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import DeleteDialog from '../components/DeleteDialog';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { TableListHead, TableListToolbar, TableMoreMenu } from '../components/_dashboard/table';
import RouteForm from '../components/routing/RouteForm';
//
import RouteService from '../services/RouteService';
import spreadRouteData from '../utils/spreadRouteData';
import { useNotification } from '../contexts/notification-context';
import { deleteRoute } from '../utils/routeUtils';
import SearchService from '../services/SearchService';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

Pins.propTypes = {
  type: PropTypes.oneOf(['construction', 'store', 'industry']).isRequired
};

export default function Pins({ type }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [routes, setRoutes] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('lastUpdated');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [routeFormOpen, setRouteFormOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [count, setCount] = useState(0);

  const notification = useNotification();
  const { t } = useTranslation();

  const CONSTR_TABLE_HEAD = [
    { id: 'route.name', label: t('route'), alignRight: false },
    { id: 'city', label: t('city'), alignRight: false },
    { id: 'postalCode', label: t('postal-code'), alignRight: false },
    { id: 'address', label: t('address'), alignRight: false },
    { id: 'region', label: t('region'), alignRight: false },
    { id: 'company', label: t('company'), alignRight: false },
    { id: 'constructionPhase', label: t('status'), alignRight: false },
    { id: 'lastVisited', label: t('last-visited'), alignRight: false },
    { id: 'lastUpdated', label: t('last-updated'), alignRight: false },
    { id: 'startDate', label: t('start-date'), alignRight: false },
    { id: 'finishDate', label: t('finish-date'), alignRight: false },
    { id: '' }
  ];

  const BASE_TABLE_HEAD = [
    'route.name',
    'city',
    'postalCode',
    'address',
    'region',
    'company',
    'lastVisited',
    'lastUpdated'
  ]
    .map((field) => CONSTR_TABLE_HEAD.find((f) => f.id === field))
    .concat({ id: 'potential', label: 'Potential', alignRight: false });

  useEffect(() => {
    // if (routes.length !== 0 && routes.length >= count) return;
    if (!filterName) {
      RouteService.getPins(type, page + 1, rowsPerPage)
        .then((response) => {
          const { data, count } = response.data;
          setRoutes(data);
          setCount(count);
        })
        .catch((e) => {
          setLoading(false);
          notification.notify(e);
        });
    } else {
      SearchService.searchPins(filterName, type, page + 1, rowsPerPage)
        .then((response) => {
          const { data, count } = response.data;

          setRoutes(data);
          setCount(count);
        })
        .catch((e) => {
          setLoading(false);
          notification.notify(e);
        });
    }
  }, [type, page, rowsPerPage, notification, filterName]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = routes.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRoutes([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if (page > 0) setPage(0);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - routes.length) : 0;

  const filteredRoutes = applySortFilter(routes, getComparator(order, orderBy));

  const isrouteNotFound = filteredRoutes.length === 0;

  return (
    <Page title={`${t(type)[0].toUpperCase() + t(type).substring(1)} | Wesy Dashboard`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography sx={{ textTransform: 'capitalize' }} variant="h4" gutterBottom>
            {t(type)}
          </Typography>
          <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-evenly">
            <Button
              variant="contained"
              onClick={() => {
                RouteService.getExcelSheet(type).catch((e) => {
                  notification.notify(e);
                });
              }}
              startIcon={<Icon icon="bx:bx-export" />}
            >
              {t('export-list')}
            </Button>
            <Button
              variant="contained"
              onClick={() => setRouteFormOpen(true)}
              startIcon={<Icon icon={plusFill} />}
            >
              {t('new-route')}
            </Button>
          </Stack>
        </Stack>

        <DeleteDialog
          title={t('delete-pin')}
          description={t('irreversible')}
          open={deleteDialogOpen}
          handleClose={() => setDeleteDialogOpen(false)}
          toBeDeleted={toBeDeleted}
          onDelete={(received, setLoading, handleClose) => {
            RouteService.deletePin(received)
              .then(({ data }) => {
                setCount((prev) => prev - 1);
                setPage(0);
                setRoutes((prev) => prev.filter((prev) => prev._id !== received));
                setLoading(false);
                notification.notify(data.message);
                handleClose();
              })
              .catch((e) => {
                setLoading(false);
                notification.notify(e);
              });
          }}
        />

        <RouteForm open={routeFormOpen} handleClose={() => setRouteFormOpen(false)} type={type} />

        <Card>
          <TableListToolbar
            numSelected={selected.length}
            deleteItems={(e) => console.log(e)}
            filterName={filterName}
            onFilterName={handleFilterByName}
            clear={Boolean(filterName)}
            clearFilter={() => {
              setFilterName('');
            }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={type === 'construction' ? CONSTR_TABLE_HEAD : BASE_TABLE_HEAD}
                  rowCount={routes.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredRoutes.map((row) => {
                    // filteredRoutes.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row) => {
                    const {
                      _id,
                      route,
                      name,
                      city,
                      postalCode,
                      address,
                      region,
                      startDate,
                      finishDate,
                      constructionPhase,
                      lastVisited,
                      lastUpdated,
                      company,
                      potential
                    } = row;

                    const isItemSelected = selected.indexOf(_id) !== -1;
                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, _id)}
                          />
                        </TableCell> */}
                        <TableCell align="left">
                          <Typography
                            component={RouterLink}
                            color="inherit"
                            sx={{ textDecoration: 'none' }}
                            to={`/dashboard/routes/pin/details/${_id}`}
                            variant="subtitle2"
                          >
                            {route.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{city || t('none')}</TableCell>
                        <TableCell align="left">{postalCode || t('none')}</TableCell>
                        <TableCell align="left">{address || t('none')}</TableCell>
                        <TableCell align="left">{region.name}</TableCell>
                        <TableCell align="left">{company || t('none')}</TableCell>
                        {type === 'construction' && (
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={
                                (constructionPhase === 'A' && 'error') ||
                                (constructionPhase === 'B' && 'warning') ||
                                'success'
                              }
                            >
                              {sentenceCase(constructionPhase)}
                            </Label>
                          </TableCell>
                        )}
                        <TableCell align="left">
                          {lastVisited ? (
                            <Label
                              variant="ghost"
                              color={
                                (subMonths(new Date(), 1).getTime() >
                                  new Date(lastVisited).getTime() &&
                                  'error') ||
                                (subDays(new Date(), 20).getTime() >
                                  new Date(lastVisited).getTime() &&
                                  'warning') ||
                                'success'
                              }
                            >
                              {new Date(lastVisited).toLocaleDateString()}
                            </Label>
                          ) : (
                            'No visits'
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {new Date(lastUpdated).toLocaleDateString()}
                        </TableCell>
                        {type === 'construction' && (
                          <>
                            <TableCell align="left">
                              {new Date(startDate).toLocaleDateString()}
                            </TableCell>
                            <TableCell align="left">
                              {new Date(finishDate).toLocaleDateString()}
                            </TableCell>
                          </>
                        )}
                        {type !== 'construction' && (
                          <TableCell align="left">
                            {potential ? (
                              <Label
                                variant="ghost"
                                color={
                                  (potential === 'low' && 'error') ||
                                  (potential === 'medium' && 'warning') ||
                                  'success'
                                }
                              >
                                {sentenceCase(potential)}
                              </Label>
                            ) : (
                              t('none')
                            )}
                          </TableCell>
                        )}
                        <TableCell align="right">
                          <TableMoreMenu
                            viewLink={`/dashboard/routes/pin/details/${_id}`}
                            deleteItem={() => {
                              setDeleteDialogOpen(true);
                              setToBeDeleted(_id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isrouteNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            labelRowsPerPage={t('rows-per-page')}
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
