import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';

import { useNotification } from '../../contexts/notification-context';
import RouteService from '../../services/RouteService';

export default function ChangeRoute({ open, handleClose, region, pinType, pinId }) {
  const { t } = useTranslation();

  const Schema = Yup.object({
    route: Yup.string().required(t('generic-req'))
  });

  const notification = useNotification();

  const [routes, setRoutes] = useState([]);

  const formik = useFormik({
    initialValues: { route: '' },
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      RouteService.updateParentRouteOfPin(pinId, values.route)
        .then(({ data }) => {
          console.log('-------\nDATA\n----------', data);
          resetForm();
          notification.notify(data.message);
          handleClose();
        })
        .catch(console.log);
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    RouteService.getAllRoutesSummary(region, pinType)
      .then(({ data: { data } }) => {
        setRoutes(data);
      })
      .catch(console.log);
  }, [region, pinType]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>Change Route</DialogTitle>
          <DialogContent>
            <FormControl fullWidth error={Boolean(touched.route && errors.route)}>
              <InputLabel id="select-route">{t('route')}</InputLabel>
              <Select
                fullWidth
                labelId="select-route"
                id="route"
                label={t('route')}
                {...getFieldProps('route')}
              >
                {routes.length > 0 ? (
                  routes.map((route) => (
                    <MenuItem key={route._id} value={route._id}>
                      {sentenceCase(route.name)}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key="loadinghash523" value="">
                    Loading...
                  </MenuItem>
                )}
              </Select>
              <FormHelperText>{touched.route && errors.route}</FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <LoadingButton loading={isSubmitting} type="submit">
              {t('update')}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}

ChangeRoute.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
  pinType: PropTypes.string.isRequired,
  pinId: PropTypes.string.isRequired
};
