import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik, Form, FormikProvider, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DateTimePicker from '@mui/lab/DateTimePicker';

import SelectWorkersList from './routing/SelectWorkersList';
import { isAdmin, getAuthData } from '../utils/authHelpers';
import ScheduleService from '../services/ScheduleService';
import { useNotification } from '../contexts/notification-context';

export default function ScheduleDialog({ open, handleClose }) {
  const { t } = useTranslation();
  const [usersDialogOpen, setUsersDialogOpen] = useState(false);
  const [creating, setCreating] = useState(false);

  const notification = useNotification();

  const Schema = Yup.object({
    title: Yup.string().required(t('generic-req')),
    startDate: Yup.date().required(t('generic-req')),
    endDate: Yup.date().required(t('generic-req')),
    notes: Yup.string().optional(),
    users: Yup.array().of(Yup.string()).required()
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      startDate: new Date(),
      endDate: new Date(),
      notes: '',
      allDay: false,
      users: []
    },
    validationSchema: Schema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log(values);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Broadcast schedule</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} pt={1}>
            <Grid item xs={12}>
              <TextField
                label={t('title')}
                fullWidth
                {...getFieldProps('title')}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimePicker
                label={t('start-date')}
                name="startDate"
                value={values.startDate}
                id="startDate"
                onChange={(value) => {
                  setFieldValue('startDate', value);
                  if (values.endDate.getTime() < value.getTime()) {
                    setFieldValue('endDate', value);
                  }
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimePicker
                label={t('finish-date')}
                name="endDate"
                value={values.endDate}
                id="endDate"
                onChange={(value) => setFieldValue('endDate', value)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('notes')}
                multiline
                fullWidth
                {...getFieldProps('notes')}
                error={Boolean(touched.notes && errors.notes)}
                helperText={touched.notes && errors.notes}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('recipients')}
                multiline
                fullWidth
                {...getFieldProps('users')}
                error={Boolean(touched.users && errors.users)}
                helperText={touched.users && errors.users}
                inputProps={{
                  'aria-readonly': true,
                  'aria-label': `${t('choose-recipients')}, ${t('recipients-selected', {
                    length: values.users.length
                  })}`,
                  readOnly: true,
                  onClick: () => {
                    console.log('Setting open');
                    setUsersDialogOpen(true);
                  },
                  value:
                    values.users.length > 0
                      ? t('recipients-selected', { length: values.users.length })
                      : t('no-recipients-selected')
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <LoadingButton
            loading={creating}
            onClick={() => {
              ScheduleService.createBroadcastSchedule(values)
                .then(({ data }) => {
                  setCreating(false);
                  notification.notify(data.message);
                  handleClose();
                })
                .catch((e) => {
                  setCreating(false);
                  notification.notify(e);
                });
            }}
          >
            {t('create')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <SelectWorkersList
        open={usersDialogOpen}
        onClose={() => setUsersDialogOpen(false)}
        handleSelect={(selected) => {
          setFieldValue('users', selected);
        }}
        fromRegion={isAdmin() && getAuthData().user.region}
      />
    </FormikProvider>
  );
}

ScheduleDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};
