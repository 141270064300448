import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import ErrorIcon from '@mui/icons-material/Error';
import { CircularProgress } from '@mui/material';
import RouteForm from '../components/routing/RouteForm_Obsolete';

import RouteService from '../services/RouteService';
import { pushError } from '../slices/error';
import getRouteFromType from '../utils/getRouteFromType';
import { LocationSchema } from '../utils/routeUtils';
import { isSuperAdmin } from '../utils/authHelpers';
import getChangedValues from '../utils/getChangedValues';

export default function UpdateIndustryRoute() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initValues, setInitValues] = useState(null);

  useEffect(() => {
    RouteService.getRouteDetails(params.id).then(({ data: { data } }) => {
      console.log('data: ', data);
      const cleanedData = { ...data };

      if (cleanedData.__t !== 'IndustryRoute')
        return navigate(`/dashboard/routes/update/${getRouteFromType(data)}/${data._id}`, {
          replace: true
        });

      const { location } = cleanedData;
      console.log(data, cleanedData);
      cleanedData.location = { ...location, region: location.region._id };
      if (!isSuperAdmin()) delete cleanedData.location.region;
      delete cleanedData.location._id;
      cleanedData.worker = cleanedData.worker._id;
      delete cleanedData._id;
      delete cleanedData.__t;
      delete cleanedData.__v;
      delete cleanedData.id;
      delete cleanedData.notes;
      setInitValues(cleanedData);
    });
  }, [params.id, navigate]);

  console.log('init: ', initValues);
  return initValues ? (
    <RouteForm
      initialValues={initValues}
      enableReinitialize
      type="store"
      headerText="Update Industry Route"
      submitButtonText="Update route"
      schema={{
        location: isSuperAdmin()
          ? LocationSchema.shape({ region: Yup.string().required('Region is required') })
          : LocationSchema
      }}
      onFormSubmit={(values, { setSubmitting }) => {
        RouteService.updateIndustryRoute(getChangedValues(values, initValues), params.id)
          .then(({ data: { data: route } }) => {
            setSubmitting(false);
            navigate(`/dashboard/routes/details/${params.id}`, { replace: true });
          })
          .catch((e) => {
            dispatch(pushError(e));
            setSubmitting(false);
          });
      }}
    />
  ) : (
    <CircularProgress />
  );
}
