import axios from 'axios';
import { commonSuccessRespFilter, apiErrorParser } from '../utils/responseHelpers';
import { getAccessToken } from '../utils/authHelpers';

export default class AuthService {
  static config = {
    baseURL: 'https://api.wesy.ch/auth',
    // baseURL: 'http://localhost:8080/auth',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  static http = axios.create(this.config);

  static httpWithAuthHeader() {
    return axios.create({
      ...this.config,
      headers: {
        ...this.config.headers,
        Authorization: `Bearer ${getAccessToken()}`
      }
    });
  }

  // static responseHandler <Data>(response: AxiosResponse<SuccessResponse<Data>>) {
  //   return commonSuccessRespFilter<Data>(response);
  // }

  static login(loginDetails) {
    return this.http
      .post('/login', loginDetails)
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static createWorker(details) {
    return this.httpWithAuthHeader()
      .post('/users/create-worker', details)
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static createAdmin(details) {
    return this.httpWithAuthHeader()
      .post('/users/create-admin', details)
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getWorkers() {
    return this.httpWithAuthHeader()
      .get('/users/fetch/all-workers')
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getAllUsers() {
    return this.httpWithAuthHeader()
      .get('/users/fetch/users')
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getWorkersFromRegion(region) {
    return this.httpWithAuthHeader()
      .get('/users/fetch/all-workers-from-region', { params: { region } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getWorkerFromId(id) {
    return this.httpWithAuthHeader()
      .get('/users/fetch/worker', { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getAdminFromId(id) {
    return this.httpWithAuthHeader()
      .get('/users/fetch/admin', { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static updateWorker(id, data) {
    return this.httpWithAuthHeader()
      .patch('/users/update/worker', data, { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static updateAdmin(id, data) {
    return this.httpWithAuthHeader()
      .patch('/users/update/admin', data, { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getAdmins() {
    return this.httpWithAuthHeader()
      .get('/users/fetch/all-admins')
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static getWorkerRouteCount(id) {
    return this.httpWithAuthHeader()
      .get('/users/fetch/worker-route-count', { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static deleteWorker(id) {
    return this.httpWithAuthHeader()
      .delete('/users/delete/worker', { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static deleteAdmin(id) {
    return this.httpWithAuthHeader()
      .delete('/users/delete/admin', { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static validateToken() {
    return this.httpWithAuthHeader().get('/validate/token');
  }

  static getProfile() {
    return this.httpWithAuthHeader()
      .get('/profile/get-profile')
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static updateProfile(data) {
    return this.httpWithAuthHeader()
      .patch('/profile/update-profile', data)
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static updatePassword(data) {
    return this.httpWithAuthHeader()
      .patch('/users/update-password', data)
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  static updateToken(token) {
    return this.httpWithAuthHeader()
      .patch('/users/update/registration-token', { token })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }
}
