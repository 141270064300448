export function getYears(startYear) {
  const years = [];
  const currentYear = new Date().getFullYear();
  startYear = startYear || 2000;

  while (startYear < currentYear) {
    years.push((startYear += 1));
  }

  return years;
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const getMonth = (month) => months[month - 1];
