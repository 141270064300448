import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { isSuperAdmin } from '../utils/authHelpers';
import RouteForm from '../components/routing/RouteForm_Obsolete';
import RouteService from '../services/RouteService';
import { useNotification } from '../contexts/notification-context';
import { getLocationInitVals, LocationSchema } from '../utils/routeUtils';
// import { baseRouteFields } from '../utils/routeFields';

const initialValues = {
  name: '',
  lastVisited: new Date(),
  lastUpdated: new Date(),
  potential: '',
  company: '',
  worker: '',
  location: getLocationInitVals()
};

export default function CreateStoreRoute() {
  const navigate = useNavigate();
  const notification = useNotification();

  return (
    <RouteForm
      type="normal"
      headerText="Create Industry Route"
      initialValues={initialValues}
      schema={{
        location: isSuperAdmin()
          ? LocationSchema.shape({ region: Yup.string().required('Region is required') })
          : LocationSchema
      }}
      fields={[]}
      onFormSubmit={(values, actions) => {
        RouteService.createIndustryRoute(values)
          .then(({ data }) => {
            actions.setSubmitting(false);
            notification.notify(data.message);
            navigate(`/dashboard/routes/details/${data.data._id}`, { replace: true });
          })
          .catch((e) => {
            console.log('e: ', e);
            actions.setSubmitting(false);
            notification.notify(e);
          });
      }}
    />
  );
}
