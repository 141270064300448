import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Card,
  CardHeader,
  Stack,
  CardContent,
  IconButton,
  ImageList,
  ImageListItem,
  CircularProgress,
  Pagination,
  TablePagination
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';
import RouteService from '../../services/RouteService';
import { useNotification } from '../../contexts/notification-context';

const Input = styled('input')({
  display: 'none'
});

const AddPhotoForm = ({ setPhotos, setCount, setPage }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const notification = useNotification();

  const handleChange = (e) => {
    e.preventDefault();
    setLoading(true);
    RouteService.addImages(e.target.files, params.id)
      .then(({ data: resData }) => {
        setLoading(false);
        notification.notify(resData.message);
        setCount((prev) => prev + resData.data.length);
        setPage(0);
        setPhotos((prev) => [...resData.data, ...prev]);
      })
      .catch((e) => {
        setLoading(false);
        notification.notify(e);
      });
  };

  return (
    <label htmlFor="contained-button-file">
      <Input
        accept="image/*"
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleChange}
      />
      <LoadingButton loading={loading} component="span">
        {t('upload')}
      </LoadingButton>
    </label>
  );
};

const PhotoSlider = ({
  setCount,
  setPage,
  activeIndex,
  itemData,
  open,
  handleClose,
  setPhotos
}) => {
  const params = useParams();
  const notification = useNotification();
  const { t } = useTranslation();

  const [deleting, setDeleting] = useState(false);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" component="h2">
            {t('photo')}
          </Typography>
          <IconButton
            color="error"
            disabled={deleting}
            onClick={() => {
              setDeleting(true);
              RouteService.deleteImage(itemData[activeIndex]._id, params.id)
                .then(({ data: resData }) => {
                  setDeleting(false);
                  setCount((prev) => prev - 1);
                  setPage(0);
                  setPhotos((prev) => prev.filter((img) => img._id !== itemData[activeIndex]._id));
                  notification.notify(resData.message);
                  handleClose();
                })
                .catch((e) => {
                  setDeleting(false);
                  notification.notify(e);
                });
            }}
          >
            {!deleting ? <DeleteIcon /> : <CircularProgress size={20} color="error" />}
          </IconButton>
        </Stack>
      </DialogTitle>
      {itemData[activeIndex] && (
        <Image loading="lazy" publicId={itemData[activeIndex].publicId}>
          <Transformation quality="auto" fetchFormat="auto" crop="scale" />
        </Image>
      )}
    </Dialog>
  );
};

const Photos = ({ setDetails, setImages, itemData }) => {
  const [sliderOpen, setSliderOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [photos, setPhotos] = useState([]);
  const params = useParams();
  const [pagesNecessary, setPagesNecessary] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [count, setCount] = useState(0);

  const handleChangeRowsPerPage = (event) => {
    setPhotos([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (photos.length !== 0 && photos.length >= count) return;
    RouteService.getImages(params.id, page + 1, rowsPerPage)
      .then(({ data: resData }) => {
        setPhotos((prev) => [...prev, ...resData.data]);
        setPagesNecessary(resData.pagesNecessary);
        setCount(resData.count);
      })
      .catch(console.log);
    // eslint-disable-next-line
  }, [page, rowsPerPage, params.id]);

  return (
    <CloudinaryContext cloudName="judge">
      {photos.length > 0 && (
        <PhotoSlider
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          open={sliderOpen}
          itemData={photos}
          setPhotos={setPhotos}
          setPage={setPage}
          setCount={setCount}
          handleClose={() => setSliderOpen(false)}
        />
      )}
      <Card>
        <CardHeader
          title={
            <Typography variant="subtitle2" color="textSecondary">
              {t('photos')}
            </Typography>
          }
          action={<AddPhotoForm setPhotos={setPhotos} setCount={setCount} setPage={setPage} />}
          disableTypography
        />
        <CardContent>
          {photos.length > 0 ? (
            <>
              <ImageList cols={3} rowHeight={164}>
                {photos.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((item, index) => (
                  <ImageListItem
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setActiveIndex(index);
                      setSliderOpen(true);
                    }}
                    key={item.publicId}
                  >
                    <Image loading="lazy" publicId={item.publicId}>
                      <Transformation
                        width="500"
                        height="450"
                        quality="auto"
                        fetchFormat="auto"
                        crop="scale"
                      />
                    </Image>
                  </ImageListItem>
                ))}
              </ImageList>
              <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={(e, value) => setPage(value)}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[3, 6, 9, 12]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography color="textSecondary">{t('no-photo')}</Typography>
            </Stack>
          )}
        </CardContent>
      </Card>
    </CloudinaryContext>
  );
};

export default Photos;
