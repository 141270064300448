import { useEffect, useState } from 'react';
import { getMessaging, getToken } from 'firebase/messaging';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import * as locale from 'date-fns/locale';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import Alert from './components/Alert';
import { NotificationProvider } from './contexts/notification-context';
import { vapidConfig } from './utils/firebaseUtils';
import { logout } from './slices/auth';
import { mapLangToDateFnsLocale } from './utils/mapLangToLocale';

import './styles-override/react-scheduler-override.css';
import AuthService from './services/AuthService';

// ----------------------------------------------------------------------

export default function App() {
  const [updatedToken, setUpdatedToken] = useState(false);
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const messaging = getMessaging();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    // if (loggedIn) {
    //   AuthService.getProfile()
    // }
    if (!updatedToken && loggedIn) {
      getToken(messaging, vapidConfig)
        .then((currentToken) => {
          if (currentToken) {
            AuthService.updateToken(currentToken)
              .then(({ data }) => {
                setUpdatedToken(true);
                console.log('updated token');
                console.log('data: ', data);
              })
              .catch((e) => {
                console.log('ERROR: ', e, e.status);
                if (e.status === 401) {
                  localStorage.removeItem('auth_data');
                  dispatch(logout());
                }
              });
          }
        })
        .catch(console.log);
    }
  }, [messaging, updatedToken, loggedIn, dispatch]);

  console.log(process.env.REACT_APP_API_URL);
  return (
    <ThemeConfig>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={locale[mapLangToDateFnsLocale[i18n.language]]}
      >
        <NotificationProvider>
          <Alert />
          <ScrollToTop />
          <GlobalStyles />
          <BaseOptionChartStyle />
          <Router />
        </NotificationProvider>
      </LocalizationProvider>
    </ThemeConfig>
  );
}
