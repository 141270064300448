import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Stack,
  List,
  ListSubheader,
  Typography,
  IconButton,
  ListItem,
  ListItemText,
  CircularProgress,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import RouteService from '../../services/RouteService';
import { useNotification } from '../../contexts/notification-context';

const NoteDialog = ({ note, setNote, handleClose, open }) => {
  const { t } = useTranslation();
  const params = useParams();
  const notification = useNotification();

  const formik = useFormik({
    initialValues: { note },
    validationSchema: Yup.object({
      note: Yup.string().required()
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      RouteService.updateImportantNote(values, params.id)
        .then(({ data: resData }) => {
          setSubmitting(false);
          setNote(resData.data);
          notification.notify(resData.message);
          handleClose();
        })
        .catch((e) => {
          setSubmitting(false);
          notification.notify(e);
        });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Dialog open={open} onClose={handleClose}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>{t('add-note')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              multiline
              rows={3}
              {...getFieldProps('note')}
              margin="dense"
              id="note"
              label={t('note')}
              fullWidth
              error={Boolean(touched.note && errors.note)}
              helperText={touched.note && errors.note}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <LoadingButton type="submit" loading={isSubmitting}>
              {t('add-note')}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

NoteDialog.propTypes = {
  note: PropTypes.string,
  setNote: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

export default function ImportantNote() {
  const [note, setNote] = useState(null);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    RouteService.getImportantNote(params.id)
      .then(({ data }) => {
        console.log('IMportant note', data);
        setNote(data.data);
      })
      .finally(() => setLoading(false));
  }, [params.id]);

  return (
    <Card>
      <CardContent>
        {!loading ? (
          <>
            {note && note.text ? (
              <>
                <NoteDialog
                  note={note.text}
                  setNote={setNote}
                  open={dialogOpen}
                  handleClose={() => setDialogOpen(false)}
                />
                <List
                  subheader={
                    <ListSubheader
                      // component={Stack}
                      // direction="row"
                      // justifyContent="space-between"
                      // alignItems="center"
                      id="visits-list-subheader"
                    >
                      <Typography variant="subtitle2" color="textSecondary">
                        {t('important-note')}
                      </Typography>
                    </ListSubheader>
                  }
                >
                  <ListItem
                    key={note._id}
                    sx={{ pt: 2, pb: 2 }}
                    secondaryAction={
                      <IconButton edge="end" onClick={() => setDialogOpen(true)}>
                        <EditIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={note.text} />
                  </ListItem>
                </List>
              </>
            ) : (
              <Stack>
                <Typography color="secondary">{t('no-important-note')}</Typography>
              </Stack>
            )}
          </>
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
