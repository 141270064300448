import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StatsService from '../../../services/StatsService';
import BarChart from '../../charts/BarChart';

export default function AppRoutesPercentageRegion({ region }) {
  const [chartData, setChartData] = useState([]);

  const handler = ({ data: { data } }) => {
    console.log('DATA: ', data);
    setChartData(data);
  };

  useEffect(() => {
    console.log('REGION: ', region);
    if (region) {
      console.log('region is there');
      StatsService.getRoutesPercentageForRegion(region).then(handler).catch(console.log);
    } else {
      StatsService.getRoutesPercentage().then(handler).catch(console.log);
    }
  }, [region]);

  return (
    <BarChart
      title={useTranslation().t('route-count')}
      xaxis={chartData.map((dt) => dt.label)}
      data={chartData.map((dt) => dt.count)}
      seriesName="Route"
    />
  );
}
