import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Stack,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  ListSubheader,
  Avatar,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Box,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link, useParams } from 'react-router-dom';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Add from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { sentenceCase } from 'change-case';
import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import subMonths from 'date-fns/subMonths';

import Page from '../components/Page';
import SelectWorkersList from '../components/routing/SelectWorkersList';
import DeleteDialog from '../components/DeleteDialog';

import RouteService from '../services/RouteService';
import { useNotification } from '../contexts/notification-context';
import getChangedValues from '../utils/getChangedValues';
import PinForm from '../components/routing/PinForm';
import MapView from '../components/MapView';
import { isAdmin, isSuperAdmin } from '../utils/authHelpers';
import PinList from '../components/routing/PinList';

const UpdateDialog = ({ initialValues, setRouteData, open, handleClose }) => {
  const params = useParams();
  const notification = useNotification();
  const { t } = useTranslation();

  const Schema = Yup.object().shape({
    name: Yup.string().optional()
  });

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setSubmitting }) => {
      console.log('vals: ', values);
      console.log('changed: ', getChangedValues(values, initialValues));
      RouteService.updateRoute(getChangedValues(values, initialValues), params.id)
        .then(({ data: resData }) => {
          notification.notify(resData.message);
          setRouteData((prev) => ({
            ...prev,
            name: resData.data.name
          }));
          setSubmitting(false);
          handleClose();
        })
        .catch((err) => {
          setSubmitting(false);
          notification.notify(err);
        });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('update-route')}</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={3}>
              <TextField
                autoFocus
                label={t('name')}
                fullWidth
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <LoadingButton loading={isSubmitting} type="submit">
              {t('update')}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

const WorkerList = ({ setRouteData, route }) => {
  const [selectListOpen, setSelectListOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const notification = useNotification();
  const { t } = useTranslation();

  const updateHandler = ({ data: resData }) => {
    setRouteData((prev) => ({ ...prev, workers: resData.data }));
    notification.notify(resData.message);
  };

  return (
    <>
      <SelectWorkersList
        fromRegion={route.createdBy._id}
        filterFromList={route.workers.map((worker) => worker._id)} // do not show current workers of route in the list
        open={selectListOpen}
        onClose={() => setSelectListOpen(false)}
        handleSelect={(selected) => {
          console.log('SELECTED: ', selected);
          RouteService.addWorkersToRoute(route._id, selected)
            .then(updateHandler)
            .catch(notification.notify);
        }}
      />

      <DeleteDialog
        title={t('rm-worker-route')}
        description={t('rm-worker-warn')}
        open={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        toBeDeleted={toBeDeleted}
        onDelete={(received, setLoading, handleClose) => {
          RouteService.removeWorkerFromRoute(route._id, received)
            .then(updateHandler)
            .then(() => {
              setLoading(false);
              handleClose();
            })
            .catch((e) => {
              setLoading(false);
              notification.notify(e);
            });
        }}
      />

      <List
        subheader={
          <ListSubheader
            component={Stack}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            id="nested-list-subheader"
          >
            {t('workers')}
            {(isSuperAdmin() || isAdmin()) && (
              <Button onClick={() => setSelectListOpen(true)}>{t('add-worker')}</Button>
            )}
          </ListSubheader>
        }
      >
        {route.workers.map((worker) => (
          <ListItem
            key={worker._id}
            secondaryAction={
              (isSuperAdmin() || isAdmin()) && (
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    setDeleteDialogOpen(true);
                    setToBeDeleted(worker._id);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )
            }
          >
            <ListItemAvatar>
              <Avatar>
                <PermIdentityIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${worker.firstName} ${worker.lastName}`}
              secondary={worker.email}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

// export const PinChangeDialog = ({ open, handleClose, pins, setRoute }) => {
//   const [changeState, setChangeState] = useState({ changes: pins, changed: [] });
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const notification = useNotification();

//   useEffect(() => {
//     setChangeState((prev) => ({ ...prev, changes: pins }));
//     console.log('Mounting');
//   }, [pins]);

//   const { t } = useTranslation();

//   const updatePins = () => {
//     const changedPins = changeState.changes.filter((change) =>
//       changeState.changed.includes(change._id)
//     );
//     setIsSubmitting(true);
//     Promise.all(
//       changedPins.map((change) =>
//         RouteService.updatePin({ coordinates: change.coordinates }, change._id, change.pinType)
//           .then(({ data }) => notification.notify(data.message))
//           .catch(notification.notify)
//       )
//     )
//       .then(() => {
//         setRoute((prev) => ({
//           ...prev,
//           pins: prev.pins.map((pin) => {
//             const changedPin = changedPins.find((change) => change._id === pin._id);
//             // check if pin has been changed, then set the parent route state to update pin
//             return changedPin ? { ...pin, coordinates: changedPin.coordinates } : pin;
//           })
//         }));
//         notification.notify('Pins updated');
//         setIsSubmitting(false);
//         handleClose();
//       })
//       .catch((e) => {
//         notification.notify(e);
//         setIsSubmitting(false);
//       });
//   };

//   return (
//     <Dialog open={open} onClose={handleClose} fullScreen>
//       <DialogTitle>{t('update-route')}</DialogTitle>
//       <DialogContent>
//         <MapView
//           routing
//           editMode
//           height="100%"
//           onPinChange={(pinData) => {
//             console.log('NEW PIN DATA', pinData);
//             setChangeState((prev) => ({
//               changes: prev.changes.map((pin) =>
//                 pin._id === pinData._id ? { ...pin, ...pinData } : pin
//               ),
//               changed: prev.changed.includes(pinData._id)
//                 ? [...prev.changed]
//                 : [...prev.changed, pinData._id]
//             }));
//             // setChanged((prev) => (prev.includes(pinData._id) ? [...prev] : [...prev, pinData._id]));
//           }}
//           positions={changeState.changes
//             .filter((pin) => pin.coordinates.length === 2)
//             .map((pin) => {
//               console.log('PIN: ', pin);
//               return {
//                 popupText: `${pin.name}, ${pin.address}`,
//                 coordinates: pin.coordinates,
//                 pinData: {
//                   _id: pin._id,
//                   pinType: pin.pinType
//                 }
//               };
//             })}
//         />
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose}>{t('cancel')}</Button>
//         <LoadingButton loading={isSubmitting} type="submit" onClick={updatePins}>
//           {t('update')}
//         </LoadingButton>
//       </DialogActions>
//     </Dialog>
//   );
// };

export default function RouteDetails() {
  const [route, setRoute] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [pinDialogOpen, setPinDialogOpen] = useState(false);
  const [pinDeleteDialogOpen, setPinDeleteDialogOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [pinChangeDialogOpen, setPinChangeDialogOpen] = useState(false);
  const notification = useNotification();
  const params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    RouteService.getRouteDetails(params.id)
      .then(({ data: { data } }) => {
        setRoute(data);
      })
      .catch(console.log);
  }, [params.id]);

  return (
    <Page title="Route Details | Wesy Dashboard">
      <Container>
        {!route ? (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Box>
            <DeleteDialog
              title={t('delete-loc')}
              description={t('irreversible')}
              open={pinDeleteDialogOpen}
              handleClose={() => setPinDeleteDialogOpen(false)}
              toBeDeleted={toBeDeleted}
              onDelete={(received, setLoading, handleClose) => {
                RouteService.deletePin(received)
                  .then(({ data }) => {
                    notification.notify(data.message);
                    setLoading(false);
                    setRoute((route) => ({
                      ...route,
                      pins: route.pins.filter((pin) => pin._id !== received)
                    }));
                    handleClose();
                  })
                  .catch((e) => {
                    setLoading(false);
                    notification.notify(e);
                  });
              }}
            />
            <PinForm
              title={t('create-location')}
              buttonText={t('create-location')}
              open={pinDialogOpen}
              handleClose={() => setPinDialogOpen(false)}
              forConstruction={route.routeType === 'construction'}
              onSubmit={(values, initialValues, actions) => {
                const changed = getChangedValues(values, initialValues);
                RouteService.createPin(changed, route._id)
                  .then(({ data: resData }) => {
                    notification.notify(resData.message);
                    actions.setSubmitting(false);
                    setRoute((prev) => ({ ...prev, pins: [...route.pins, resData.data] }));
                    setPinDialogOpen(false);
                    actions.resetForm();
                  })
                  .catch((e) => {
                    actions.setSubmitting(false);
                    notification.notify(e);
                  });
              }}
            />
            <UpdateDialog
              initialValues={{ name: route.name }}
              setRouteData={setRoute}
              open={dialogOpen}
              handleClose={() => setDialogOpen(false)}
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                {t('route')}: {route.name}
              </Typography>
              <Button variant="contained" onClick={() => setDialogOpen(true)}>
                {t('update-route')}
              </Button>
            </Stack>
            <Grid container mb={5}>
              <Grid item xs={12} md={6}>
                <List
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      {t('route-info')}
                    </ListSubheader>
                  }
                >
                  <ListItem>
                    <ListItemText
                      sx={{ textTransform: 'capitalize' }}
                      primary={t(route.routeType)}
                      secondary={t('branch')}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={route.createdBy.name} secondary={t('region')} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <WorkerList route={route} setRouteData={setRoute} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <PinList
                  setRoute={setRoute}
                  pins={route.pins}
                  setPinDialogOpen={setPinDialogOpen}
                  setPinDeleteDialogOpen={setPinDeleteDialogOpen}
                  setToBeDeleted={setToBeDeleted}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {route.pins.length > 0 ? (
                  <MapView
                    routing
                    height="50vh"
                    positions={route.pins
                      .filter((pin) => pin.coordinates.length === 2)
                      .map((pin) => {
                        console.log('PIN: ', pin);
                        return {
                          popupText: `${pin.name}, ${pin.address}`,
                          coordinates: pin.coordinates,
                          pinData: {
                            id: pin._id,
                            type: pin.pinType
                          }
                        };
                      })}
                  />
                ) : (
                  <Typography variant="subtitle1" color="textSecondary" textAlign="center">
                    No pins yet
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </Page>
  );
}
