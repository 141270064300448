// material
import {
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Container,
  Typography
} from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// components
import Page from '../components/Page';
import {
  AppRoutesPercentage,
  AppRoutesPercentageRegion,
  AppStoreRouteConcentration,
  AppConstructionRouteConcentration,
  AppPinsByPotential,
  AppBranchVisits,
  AppWorkerRoutesCount,
  AppRouteCoordinates,
  AppRoutesCount,
  AppWorkerVisitsTable
} from '../components/_dashboard/app';
import MapView from '../components/MapView';

// helpers
import { isWorker, isAdmin, isSuperAdmin } from '../utils/authHelpers';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { t } = useTranslation();

  return (
    <Page title="Dashboard | Wesy">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{t('welcome')}</Typography>
        </Box>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppRoutesCount />
          </Grid> */}

          {isSuperAdmin() && (
            <Grid item xs={12} md={6} lg={8}>
              <AppStoreRouteConcentration />
            </Grid>
          )}

          {isAdmin() ? (
            <Grid item xs={12} md={6} lg={8}>
              <AppRoutesPercentageRegion />
            </Grid>
          ) : (
            isSuperAdmin() && (
              <Grid item xs={12} md={6} lg={4}>
                <AppRoutesPercentage />
              </Grid>
            )
          )}

          {isSuperAdmin() && (
            <Grid item xs={12} md={6} lg={8}>
              <AppConstructionRouteConcentration />
            </Grid>
          )}

          {(isAdmin() || isSuperAdmin()) && (
            <Grid item xs={12} md={6} lg={4}>
              <AppPinsByPotential />
            </Grid>
          )}

          {(isAdmin() || isSuperAdmin()) && (
            <Grid item xs={12}>
              <AppWorkerVisitsTable type="visits" />
            </Grid>
          )}

          {(isAdmin() || isSuperAdmin()) && (
            <Grid item xs={12}>
              <AppWorkerVisitsTable type="newMembers" />
            </Grid>
          )}

          {(isAdmin() || isSuperAdmin()) && (
            <Grid item xs={12}>
              <AppRouteCoordinates title={t('constr-route-pin')} type="construction" />
            </Grid>
          )}

          {isSuperAdmin() && (
            <Grid item xs={12}>
              <Stack spacing={2}>
                <AppBranchVisits branch="construction" title={t('constr-branch-visits')} />
                <AppBranchVisits branch="store" title={t('store-branch-visits')} />
                <AppBranchVisits branch="industry" title={t('industry-branch-visits')} />
              </Stack>
            </Grid>
          )}

          {isWorker() && (
            <Grid item xs={12} md={6}>
              <AppWorkerRoutesCount />
            </Grid>
          )}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
