import { useEffect, useState } from 'react';
import { sentenceCase } from 'sentence-case';

// material
import { Card, CardHeader, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
//
import BarChart from '../../charts/BarChart';
import StatsService from '../../../services/StatsService';
import { getYears } from '../../../utils/date';

export default function AppBranchVisits({ branch, title }) {
  const [year, setYear] = useState(new Date().getFullYear());
  const [data, setData] = useState([]);

  const years = getYears();

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  useEffect(() => {
    setData([]);
    StatsService.getBranchVisits(year, branch.toLowerCase())
      .then(({ data: { data } }) => {
        setData(data.map((dt) => ({ ...dt, stringLabel: `${dt.label}/01/${year}` })));
      })
      .catch(console.log);
  }, [year, branch]);

  return (
    <BarChart
      cardHeaderAction={
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Year</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={year}
            label="Year"
            onChange={handleYearChange}
          >
            {years.map((yr) => (
              <MenuItem key={yr} value={yr}>
                {yr}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
      title={title}
      xaxis={data.map((dt) =>
        new Date(dt.stringLabel).toLocaleString('default', { month: 'long' })
      )}
      data={data.map((dt) => dt.count)}
      seriesName="Visit"
    />
  );
}
