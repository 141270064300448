import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CircularProgress
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { TableListHead, TableListToolbar, TableMoreMenu } from '../components/_dashboard/table';
//
import AuthService from '../services/AuthService';
import spreadRouteData from '../utils/spreadRouteData';
import { useNotification } from '../contexts/notification-context';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const ConfirmDeleteDialog = ({ admin, open, handleClose, setUsers }) => {
  const { t } = useTranslation();
  const notification = useNotification();
  const [emailRetyped, setEmailRetyped] = useState('');
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  const handleCleanClose = () => {
    setEmailRetyped('');
    setHasBeenClicked(false);
    handleClose();
  };

  const error = emailRetyped !== admin.email && hasBeenClicked;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('delete-user')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('delete-admin-warning')}</DialogContentText>
        <TextField
          autoFocus
          label={t('admin-email')}
          fullWidth
          margin="dense"
          variant="standard"
          value={emailRetyped}
          onChange={(e) => setEmailRetyped(e.target.value)}
          error={error}
          helperText={error ? t('admin-email-match') : t('retype-admin-email')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCleanClose}>{t('cancel')}</Button>
        <Button
          onClick={() => {
            setHasBeenClicked(true);
            if (emailRetyped === admin.email) {
              AuthService.deleteAdmin(admin._id)
                .then(({ data }) => {
                  notification.notify(data.message);
                  setUsers((users) => users.filter((user) => user._id !== admin._id));
                  handleCleanClose();
                })
                .catch(notification.notify);
            }
          }}
        >
          {t('delete-user-action')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function AdminsTable() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState({ email: null, _id: null });

  const TABLE_HEAD = [
    // { id: 'locations', label: 'Locations', alignRight: false },
    // { id: 'company', label: 'Company', alignRight: false },
    { id: 'name', label: t('name'), alignRight: false },
    { id: 'email', label: t('email'), alignRight: false },
    { id: 'region', label: t('region'), alignRight: false },
    { id: 'role', label: t('role'), alignRight: false },
    // { id: 'startDate', label: 'Start Date', alignRight: false },
    // { id: 'finishDate', label: 'Finish Date', alignRight: false },
    // { id: 'constructionPhase', label: 'Status', alignRight: false },
    { id: '' }
  ];

  useEffect(() => {
    AuthService.getAdmins()
      .then((response) => {
        const { data } = response.data;

        const filteredData = data.map((dt) => ({
          ...dt,
          region: dt.region.name,
          role: dt.roles.includes('ADMIN') && 'admin',
          name: `${dt.firstName} ${dt.lastName}`
        }));

        console.log('filtered: ', filteredData);
        setUsers(filteredData);
      })
      .catch(console.log);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title={t('admins')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography sx={{ textTransform: 'capitalize' }} variant="h4" gutterBottom>
            {t('admins')}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/users/new/admin"
            startIcon={<Icon icon={plusFill} />}
          >
            {t('new-admin')}
          </Button>
        </Stack>

        <ConfirmDeleteDialog
          setUsers={setUsers}
          open={deleteDialogOpen}
          handleClose={() => {
            setDeleteDialogOpen(false);
            setSelectedAdmin({ email: null, _id: null });
          }}
          admin={selectedAdmin}
        />

        <Card>
          <TableListToolbar
            numSelected={selected.length}
            deleteItems={(e) => console.log(e)}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { _id, name, email, region, role } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, _id)}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2">{name}</Typography>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{region}</TableCell>
                          {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}
                          <TableCell align="left">
                            <Label variant="ghost" color="success">
                              {t(role)}
                            </Label>
                          </TableCell>
                          <TableCell align="right">
                            <TableMoreMenu
                              editLink={`/dashboard/users/update/admin/${_id}`}
                              deleteItem={(e) => {
                                setSelectedAdmin({ email, _id });
                                setDeleteDialogOpen(true);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            labelRowsPerPage={t('rows-per-page')}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
