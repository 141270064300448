import { useTranslation } from 'react-i18next';
import FetchDataPieChart from '../../charts/FetchDataPieChart';
import StatsService from '../../../services/StatsService';

export default function AppRoutesPotential({ region }) {
  return (
    <FetchDataPieChart
      title={useTranslation().t('pin-potential-chart')}
      loadData={(setChartData) => {
        const handler = ({ data: { data } }) => {
          console.log('data in region: ', data);
          setChartData(data);
        };
        if (region) {
          StatsService.getPinPercentageByPotentialForRegion(region)
            .then(handler)
            .catch(console.log);
        } else {
          StatsService.getPinPercentageByPotential().then(handler).catch(console.log);
        }
      }}
    />
  );
}
