import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import BarChart from '../../charts/BarChart';
import StatsService from '../../../services/StatsService';

function AppRouteConcentration({ title, loadData }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    loadData(setChartData);
  }, [loadData]);

  return (
    <BarChart
      horizontal
      barOptions={{ barHeight: '28%' }}
      title={title}
      xaxis={chartData.map((dt) => dt.label)}
      data={chartData.map((dt) => dt.count)}
      seriesName="Route"
    />
  );
}

export function AppStoreRouteConcentration() {
  const { t } = useTranslation();
  return (
    <AppRouteConcentration
      title={t('store-conc-header')}
      loadData={(setChartData) => {
        StatsService.getHighConc('store')
          .then(({ data: { data } }) => {
            setChartData(data);
          })
          .catch(console.log);
      }}
    />
  );
}

export function AppConstructionRouteConcentration() {
  return (
    <AppRouteConcentration
      title={useTranslation().t('constr-conc-header')}
      loadData={(setChartData) => {
        StatsService.getHighConc('construction')
          .then(({ data: { data } }) => {
            setChartData(data);
          })
          .catch(console.log);
      }}
    />
  );
}
