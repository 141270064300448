import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardHeader, CardContent, Stack, CircularProgress, Typography } from '@mui/material';

import StatsService from '../../../services/StatsService';
import { useNotification } from '../../../contexts/notification-context';

export default function AppWorkerRoutesCount() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const notification = useNotification();

  useEffect(() => {
    setLoading(true);
    StatsService.getWorkerRoutesCount()
      .then(({ data: { data } }) => {
        console.log('DATA: ', data);
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        notification.notify(new Error(t(e.message)));
      });
    //  eslint-disable-next-line
  }, []);

  return (
    <Card>
      <CardHeader title={t('your-routes')} />
      <CardContent>
        {loading ? (
          <Stack justifyContent="center" alignItems="center" sx={{ p: 2 }}>
            <CircularProgress />
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="flex-start" alignItems="center">
            <Card sx={{ p: 2, width: 'auto', bgcolor: 'primary.main', color: '#fff' }}>
              <Typography variant="h3" gutterBottom>
                {data}
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'uppercase' }}>
                {t('routes')}
              </Typography>
            </Card>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
