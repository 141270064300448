import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import editFill from '@iconify/icons-eva/edit-fill';
import viewFill from '@iconify/icons-eva/search-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

export default function TableMoreMenu({ disableView, viewLink, editLink, deleteItem, edit }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {viewLink && (
          <MenuItem component={RouterLink} to={viewLink || '#'} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={viewFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={t('view')} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {editLink && (
          <MenuItem component={RouterLink} to={editLink || '#'} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              sx={{ textTransform: 'capitalize' }}
              primary={t('edit')}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {deleteItem && (
          <MenuItem onClick={deleteItem} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              sx={{ textTransform: 'capitalize' }}
              primary={t('delete')}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
