import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import { CircularProgress } from '@mui/material';
import RouteForm from '../components/routing/RouteForm_Obsolete';

import RouteService from '../services/RouteService';
import { useNotification } from '../contexts/notification-context';
import getRouteFromType from '../utils/getRouteFromType';
import { constrLinearFields, ExtendedConstrSchema } from '../utils/routeUtils';

export default function UpdateConstructionRoute() {
  const params = useParams();
  const navigate = useNavigate();
  const notification = useNotification();
  const [initValues, setInitValues] = useState(null);

  const getChangedValues = (values, initialValues) =>
    Object.entries(values).reduce((acc, [key, value]) => {
      const hasChanged = initialValues[key] !== value;

      if (hasChanged) acc[key] = value;

      return acc;
    }, {});

  useEffect(() => {
    RouteService.getRouteDetails(params.id).then(({ data: { data } }) => {
      const cleanedData = { ...data };
      if (cleanedData.__t !== 'ConstructionRoute')
        return navigate(`/dashboard/routes/update/${getRouteFromType(data)}/${data._id}`, {
          replace: true
        });

      cleanedData.locations = cleanedData.locations.map((location) => {
        const rt = { ...location, region: location.region._id };
        delete rt._id;

        return rt;
      });

      cleanedData.locations.sort((a, b) => (a.major ? -1 : 1));

      cleanedData.worker = cleanedData.worker._id;
      delete cleanedData._id;
      delete cleanedData.__t;
      delete cleanedData.__v;
      delete cleanedData.id;
      delete cleanedData.notes;
      delete cleanedData.images;
      setInitValues(cleanedData);
    });
  }, [params.id]);

  return initValues ? (
    <RouteForm
      initialValues={initValues}
      enableReinitialize
      type="construction"
      schema={ExtendedConstrSchema}
      fields={constrLinearFields}
      headerText="Update Construction Route"
      submitButtonText="Update route"
      onFormSubmit={(values, { setSubmitting }) => {
        console.log('values on submit: ', values);
        setSubmitting(false);
        console.log('changed: ', getChangedValues(values, initValues));
        RouteService.updateConstructionRoute(getChangedValues(values, initValues), params.id)
          .then(({ data }) => {
            notification.notify(data.message);
            setSubmitting(false);
            navigate(`/dashboard/routes/details/${params.id}`, { replace: true });
          })
          .catch((e) => {
            setSubmitting(false);
            notification.notify(e);
          });
      }}
    />
  ) : (
    <CircularProgress />
  );
}
