export const saveAuthData = (data) => {
  localStorage.setItem('auth_data', JSON.stringify(data));
};

export const getAuthData = () => {
  const str = localStorage.getItem('auth_data');
  const data = str ? JSON.parse(str) : null;

  return data;
};

export const editAuthData = (cb) => {
  if (!getAuthData()) return null;
  saveAuthData(cb(getAuthData()));
};

export const getAccessToken = () => {
  const data = getAuthData();
  if (!data) return null;
  return data.token;
};

export const isSuperAdmin = () => {
  const authData = getAuthData();
  if (!authData) return false;
  if (!authData.user || !authData.user.roles.includes('SUPER_ADMIN')) return false;
  return true;
};

export const isAdmin = () => {
  const authData = getAuthData();
  if (!authData) return false;
  if (!authData.user || authData.user.roles.length !== 2 || !authData.user.roles.includes('ADMIN'))
    return false;
  return true;
};

export const isWorker = () => {
  const authData = getAuthData();
  if (!authData) return false;

  if (!authData.user || authData.user.roles.length !== 1 || !authData.user.roles.includes('WORKER'))
    return false;
  return true;
};

// export const userEmailIsVerified = () => {
//   const data = getAuthData();
//   if (!data) return false;

//   return Boolean(data.user.email && data.user.verified);
// }
