import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// material
import { CssBaseline } from '@mui/material';
import * as locales from '@mui/material/locale';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { mapLangToMuiLocale } from '../utils/mapLangToLocale';

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};

export default function ThemeConfig({ children }) {
  const { i18n } = useTranslation();
  const themeWithLocale = useMemo(() => {
    const themeOptions = {
      palette,
      shape,
      typography,
      shadows,
      customShadows
    };
    const theme = createTheme(themeOptions, locales[mapLangToMuiLocale[i18n.language]]);
    theme.components = componentsOverride(theme);
    return theme;
  }, [i18n.language]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeWithLocale}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
