import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Container,
  List,
  ListItemButton,
  ListItemText,
  Dialog,
  DialogContent,
  Grid,
  Skeleton
} from '@mui/material';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import ScheduleDialog from '../components/ScheduleDialog';
import AuthService from '../services/AuthService';
import { isSuperAdmin, isAdmin } from '../utils/authHelpers';

const LoadingSkeleton = () => (
  <List>
    {[1, 2].map((n) => (
      <ListItemButton key={n}>
        <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
      </ListItemButton>
    ))}
  </List>
);

const Users = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const [admins, setAdmins] = useState([]);
  const [workers, setWorkers] = useState([]);

  useEffect(() => {
    AuthService.getAdmins()
      .then(({ data: { data } }) => {
        setAdmins(data);
      })
      .catch(console.log);

    AuthService.getWorkers()
      .then(({ data: { data } }) => {
        setWorkers(data);
      })
      .catch(console.log);
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={isSuperAdmin() ? 6 : 12}>
            {isSuperAdmin() && <Typography variant="subtitle1">{t('workers')}</Typography>}
            {/* {} */}
            {workers.length > 0 ? (
              <List>
                {workers.map((worker) => (
                  <ListItemButton
                    key={worker._id}
                    component={Link}
                    to={`/dashboard/planning/view/${worker._id}`}
                  >
                    <ListItemText
                      secondaryTypographyProps={{ noWrap: true }}
                      primary={`${worker.firstName} ${worker.lastName}`}
                      secondary={worker.email}
                    />
                  </ListItemButton>
                ))}
              </List>
            ) : (
              <LoadingSkeleton />
            )}
          </Grid>
          {isSuperAdmin() && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1">{t('admins')}</Typography>
              {admins.length > 0 ? (
                <List>
                  {admins.map((admin) => (
                    <ListItemButton
                      key={admin._id}
                      component={Link}
                      to={`/dashboard/planning/view/${admin._id}`}
                    >
                      <ListItemText
                        secondaryTypographyProps={{ noWrap: true }}
                        primary={`${admin.firstName} ${admin.lastName}`}
                        secondary={admin.email}
                      />
                    </ListItemButton>
                  ))}
                </List>
              ) : (
                <LoadingSkeleton />
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

Users.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export function PlannerJunction() {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);

  return (
    <Page title={t('planning')}>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {t('planning')}
        </Typography>
        <Users open={dialogOpen} handleClose={() => setDialogOpen(false)} />
        <List>
          <ListItemButton component={Link} to="/dashboard/planning/view">
            <ListItemText primary={t('my-schedule')} secondary={t('view-schedule')} />
          </ListItemButton>
          {(isSuperAdmin() || isAdmin()) && (
            <>
              <ListItemButton onClick={() => setDialogOpen(true)}>
                <ListItemText primary={t('other-schedule')} secondary={t('schedule-admin-info')} />
              </ListItemButton>
              <ListItemButton onClick={() => setScheduleDialogOpen(true)}>
                <ListItemText primary={t('broadcast-schedule')} secondary={t('broadcast-expl')} />
              </ListItemButton>
            </>
          )}
        </List>
        {(isSuperAdmin() || isAdmin()) && (
          <ScheduleDialog
            open={scheduleDialogOpen}
            handleClose={() => setScheduleDialogOpen(false)}
          />
        )}
      </Container>
    </Page>
  );
}
