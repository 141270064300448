import axios from 'axios';
import fileDownload from 'js-file-download';
import { apiErrorParser, commonSuccessRespFilter } from '../utils/responseHelpers';
import { getAccessToken } from '../utils/authHelpers';
import { mapsAPIKey } from '../utils/routeUtils';

class RouteService {
  constructor() {
    this.http = axios.create({
      baseURL: 'https://api.wesy.ch/routes',
      // baseURL: 'http://localhost:8080/routes',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // eslint-disable-next-line
    this.http.interceptors.request.use(function (config) {
      return {
        ...config,
        headers: { ...config.headers, Authorization: `Bearer ${getAccessToken()}` }
      };
    });
  }

  // getToken() {
  //   return localStorage.getItem('token');
  // }

  getPins(type, page, limit) {
    return this.http
      .get(`/fetch/pins/${type}`, { params: { page, limit } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  getExcelSheet(type) {
    // eslint-disable-next-line
    const interceptor = this.http.interceptors.request.use(function (config) {
      return { ...config, responseType: 'blob' };
    });

    return this.http
      .get(`/fetch/pins-xlsx/${type}`)
      .then((response) => {
        console.log(response);
        fileDownload(response.data, `${type}-pins.xlsx`);
        this.http.interceptors.request.eject(interceptor);
        // this.http.interceptors.request.eject(interceptor);
      })
      .catch(apiErrorParser);
  }

  getConstructionRoutes() {
    return this.http.get('/fetch/construction').then(commonSuccessRespFilter).catch(apiErrorParser);
  }

  getStoreRoutes() {
    return this.http.get('/fetch/store').then(commonSuccessRespFilter).catch(apiErrorParser);
  }

  getIndustryRoutes() {
    return this.http.get('/fetch/industry').then(commonSuccessRespFilter).catch(apiErrorParser);
  }

  getAllRoutes(page, limit) {
    return this.http
      .get('/fetch/all', { params: { page, limit } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  getRegion(id) {
    return this.http
      .get('/regions/get-region', { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  updateRegion(id, name) {
    return this.http
      .patch('/regions/update', { name }, { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  getAllRoutesInRegion(id, page, limit) {
    return this.http
      .get('/fetch/routes-in-region', { params: { id, page, limit } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  getAllRoutesSummary(regionId, type) {
    return this.http
      .get(`/fetch/routes-summary/${type}`, { params: { regionId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  getWorkerRoutes() {
    return this.http
      .get('/fetch/worker-routes')
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  getAllRegions() {
    return this.http.get('/fetch/all-regions').then(commonSuccessRespFilter).catch(apiErrorParser);
  }

  createRoute(data, type) {
    return this.http.post(`/new/${type}`, data).then(commonSuccessRespFilter).catch(apiErrorParser);
  }

  createPin(data, routeId) {
    return this.http
      .patch('/update/route/add-pin', data, { params: { routeId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  createConstructionRoute(data) {
    return this.http
      .post('/new/construction', data)
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  updateRoute(data, id) {
    return this.http
      .patch(`/update/route-details`, data, { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  updateParentRouteOfPin(pinId, route) {
    return this.http
      .patch(`/update/move-pin`, { route }, { params: { pinId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  updatePin(data, pinId, type) {
    return this.http
      .patch(`/update/pin/${type}`, data, { params: { pinId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  updateConstructionRoute(data, id) {
    return this.http
      .patch(`/update/construction`, data, { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  updateStoreRoute(data, id) {
    return this.http
      .patch(`/update/store`, data, { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  updateIndustryRoute(data, id) {
    return this.http
      .patch(`/update/industry`, data, { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  createStoreRoute(data) {
    return this.http.post('/new/store', data).then(commonSuccessRespFilter).catch(apiErrorParser);
  }

  createIndustryRoute(data) {
    return this.http
      .post('/new/industry', data)
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  getRouteDetails(id) {
    return this.http.get('/fetch/route-details', { params: { id } });
  }

  rearrangePins(routeId, pins) {
    return this.http
      .patch('/update/rearrange-pins', { pins }, { params: { routeId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  getPinDetails(pinId) {
    return this.http.get('/fetch/pin-details', { params: { pinId } });
  }

  addNote(data, pinId) {
    return this.http
      .post('/update/add-note', data, { params: { pinId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  updateImportantNote(data, pinId) {
    return this.http
      .patch('/update/important-note', data, { params: { pinId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  addImages(files, pinId) {
    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append('images', file);
    });

    return this.http
      .post('/update/add-images', formData, { params: { pinId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  addVisit(data, pinId) {
    console.log('fata@ ', data);
    return this.http
      .post('/update/add-visit', data, { params: { pinId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  getNotes(pinId, page, limit) {
    return this.http
      .get('/fetch/notes', { params: { pinId, page, limit } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  getImages(pinId, page, limit) {
    return this.http
      .get('/fetch/images', { params: { pinId, page, limit } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  getVisits(pinId, page, limit) {
    return this.http
      .get('/fetch/visits', { params: { pinId, page, limit } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  getImportantNote(pinId) {
    return this.http
      .get('/fetch/important-note', { params: { pinId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  deleteNote(noteId, pinId) {
    return this.http
      .delete('/update/delete-note', { params: { noteId, pinId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  deleteVisit(visitId, pinId) {
    return this.http
      .delete('/update/delete-visit', { params: { visitId, pinId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  deleteImage(imgId, pinId) {
    return this.http
      .delete('/update/delete-image', { params: { imgId, pinId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  addWorkersToRoute(routeId, workers) {
    return this.http
      .patch('/update/add-workers', { workers }, { params: { routeId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  removeWorkerFromRoute(routeId, workerId) {
    return this.http
      .delete('/update/remove-worker', { params: { routeId, workerId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  deleteRoute(id) {
    return this.http
      .delete('/delete', { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  deletePin(pinId) {
    return this.http
      .delete('/delete/pin', { params: { pinId } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  createRegion(name) {
    return this.http
      .post('/regions/new', { name })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  deleteRegion(id) {
    return this.http
      .delete('/regions/delete', { params: { id } })
      .then(commonSuccessRespFilter)
      .catch(apiErrorParser);
  }

  // eslint-disable-next-line
  mapsReverseGeolocate(lat, lng) {
    return axios.get(
      'https://maps.googleapis.com/maps/api/geocode/json',
      // ?latlng=40.714224,-73.961452&key=YOUR_API_KEY
      {
        params: {
          latlng: `${lat},${lng}`,
          key: mapsAPIKey
        }
      }
    );
  }
}

export default new RouteService();
