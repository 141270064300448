import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import DeleteDialog from '../components/DeleteDialog';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { TableListHead, TableListToolbar, TableMoreMenu } from '../components/_dashboard/table';
//
import RouteService from '../services/RouteService';
import { useNotification } from '../contexts/notification-context';
import SearchService from '../services/SearchService';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function AllRoutes() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [routes, setRoutes] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [count, setCount] = useState(0);

  const { t } = useTranslation();

  const notification = useNotification();

  const TABLE_HEAD = [
    // { id: 'locations', label: 'Locations', alignRight: false },
    // { id: 'company', label: 'Company', alignRight: false },
    { id: 'name', label: t('name'), alignRight: false },
    { id: 'city', label: t('cities'), alignRight: false },
    { id: 'routeType', label: sentenceCase(t('branch')), alignRight: false },
    // { id: 'startDate', label: 'Start Date', alignRight: false },
    // { id: 'finishDate', label: 'Finish Date', alignRight: false },
    // { id: 'constructionPhase', label: 'Status', alignRight: false },
    { id: '' }
  ];

  useEffect(() => {
    // if (routes.length !== 0 && routes.length >= count) return;
    if (!filterName) {
      RouteService.getAllRoutes(page + 1, rowsPerPage)
        .then((response) => {
          const { data, count } = response.data;

          console.log('data:', data);

          setRoutes(data);
          setCount(count);
        })
        .catch(() => setLoading(false));
    } else {
      SearchService.searchRoutes(filterName, page + 1, rowsPerPage)
        .then((response) => {
          const { data, count } = response.data;

          setRoutes(data);
          setCount(count);
        })
        .catch(() => setLoading(false));
    }
  }, [page, rowsPerPage, count, routes.length, filterName]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = routes.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRoutes([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if (page > 0) setPage(0);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - routes.length) : 0;

  const filteredRoutes = applySortFilter(routes, getComparator(order, orderBy));

  const isrouteNotFound = filteredRoutes.length === 0;

  return (
    <Page title={t('all-routes')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('all-routes')}
          </Typography>
        </Stack>

        <DeleteDialog
          title="Delete this route?"
          description="This action is irreversible."
          open={deleteDialogOpen}
          handleClose={() => setDeleteDialogOpen(false)}
          toBeDeleted={toBeDeleted}
          onDelete={(received, setLoading, handleClose) => {
            RouteService.deleteRoute(received)
              .then(({ data }) => {
                setCount((prev) => prev - 1);
                setPage(0);
                setRoutes((prev) => prev.filter((prev) => prev._id !== received));
                setLoading(false);
                notification.notify(data.message);
                handleClose();
              })
              .catch((e) => {
                setLoading(false);
                notification.notify(e);
              });
          }}
        />
        <Card>
          <TableListToolbar
            numSelected={selected.length}
            deleteItems={(e) => console.log(e)}
            filterName={filterName}
            onFilterName={handleFilterByName}
            clear={Boolean(filterName)}
            clearFilter={() => {
              setFilterName('');
            }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={routes.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredRoutes.map((row) => {
                    // filteredRoutes.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row) => {
                    const { _id, name, pins, routeType } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, _id)}
                          />
                        </TableCell> */}
                        <TableCell align="left">
                          <Typography
                            component={RouterLink}
                            to={`/dashboard/routes/details/${_id}`}
                            color="inherit"
                            sx={{ textDecoration: 'none' }}
                            variant="subtitle2"
                          >
                            {t('route')}: {name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="body2"
                            color={pins.length > 0 ? 'textPrimary' : 'error'}
                          >
                            {pins.length > 0
                              ? pins.map((pin) => pin.city).join(' - ')
                              : t('no-loc')}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                          {t(routeType)}
                        </TableCell>
                        {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                        <TableCell align="right">
                          <TableMoreMenu
                            viewLink={`/dashboard/routes/details/${_id}`}
                            deleteItem={() => {
                              setDeleteDialogOpen(true);
                              setToBeDeleted(_id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {isrouteNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            labelRowsPerPage={t('rows-per-page')}
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
