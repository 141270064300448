import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import {
  Card,
  Button,
  Grid,
  Container,
  Stack,
  Typography,
  CircularProgress,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

import Page from '../components/Page';
import RouteService from '../services/RouteService';
import { useNotification } from '../contexts/notification-context';

function CreateRegionDialog({ open, handleClose, addRegion }) {
  const notification = useNotification();
  const { t } = useTranslation();

  const Schema = Yup.object().shape({
    name: Yup.string().trim().required(t('generic-req'))
  });

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: Schema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      console.log('vals: ', values);
      RouteService.createRegion(values.name)
        .then(({ data }) => {
          const { data: newRegion } = data;
          addRegion({
            _id: newRegion._id,
            name: newRegion.name
          });
          notification.notify(data.message);
          resetForm();
          handleClose();
        })
        .catch((e) => {
          notification.notify(e);
          setSubmitting(false);
        });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('create-region')}</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              label={t('region-name')}
              fullWidth
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <LoadingButton loading={isSubmitting} type="submit">
              {t('create')}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}

export default function AllRegions() {
  const [regions, setRegions] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    RouteService.getAllRegions().then(({ data: { data } }) => {
      setRegions(
        data.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
      );
    });
  }, []);

  const addRegion = (regionData) => setRegions((prev) => [...prev, regionData]);

  return (
    <Page title={t('regions')}>
      <Container>
        <CreateRegionDialog
          open={createDialogOpen}
          handleClose={() => setCreateDialogOpen(false)}
          addRegion={addRegion}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography sx={{ textTransform: 'capitalize' }} variant="h4" gutterBottom>
            {t('regions')}
          </Typography>
          <Button
            onClick={() => setCreateDialogOpen(true)}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            {t('new-region')}
          </Button>
        </Stack>
        {regions.length > 0 ? (
          <Grid container spacing={2}>
            {regions.map((region) => (
              <Grid key={region._id} item xs={12} md={6}>
                <Card>
                  <ListItem
                    button
                    sx={{ pt: 2, pb: 2 }}
                    component={Link}
                    to={`/dashboard/routes-in-region/${region._id}`}
                  >
                    <ListItemText
                      primary={
                        <>
                          <Typography variant="h6">{region.name}</Typography>
                        </>
                      }
                    />
                  </ListItem>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </Container>
    </Page>
  );
}
