import { filter } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  CardHeader,
  Grid,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Box,
  CircularProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components

import { AppRoutesPercentageRegion, AppPinsByPotential } from '../components/_dashboard/app';

import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { TableListHead, TableListToolbar, TableMoreMenu } from '../components/_dashboard/table';
import DeleteDialog from '../components/DeleteDialog';

//
import RouteService from '../services/RouteService';
import spreadRouteData from '../utils/spreadRouteData';
import { useNotification } from '../contexts/notification-context';
import { deleteRoute } from '../utils/routeUtils';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_route) => _route.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function UpdateRegionDialog({ open, handleClose, setName, name }) {
  const notification = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const Schema = Yup.object().shape({
    name: Yup.string().trim().required(t('generic-req'))
  });

  const formik = useFormik({
    initialValues: {
      name
    },
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      RouteService.updateRegion(params.id, values.name)
        .then(({ data }) => {
          const { data: newRegion } = data;
          setName(newRegion.name);
          notification.notify(data.message);
          resetForm();
          handleClose();
        })
        .catch((e) => {
          notification.notify(e);
          setSubmitting(false);
        });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('update-region')}</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              label={t('region-name')}
              fullWidth
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <LoadingButton loading={isSubmitting} type="submit">
              {t('update')}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}

const SettingsDialog = ({ open, handleClose, setDeleteDialogOpen, setUpdateDialogOpen }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('settings')}</DialogTitle>
      <DialogContent>
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setUpdateDialogOpen(true)}>
              <ListItemIcon>
                <Icon name="fluent:phone-update-checkmark-20-regular" />
              </ListItemIcon>
              <ListItemText primary={t('update-region')} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setDeleteDialogOpen(true)}>
              <ListItemIcon>{/* <DraftsIcon /> */}</ListItemIcon>
              <ListItemText primary={t('delete-region')} />
            </ListItemButton>
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
};

const DeleteRegionDialog = ({ region, open, handleClose }) => {
  const notification = useNotification();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [nameRetyped, setNameRetyped] = useState('');
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  const handleCleanClose = () => {
    setNameRetyped('');
    setHasBeenClicked(false);
    handleClose();
  };

  const error = nameRetyped !== region.name && hasBeenClicked;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('delete-region')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans>delete-region-warn</Trans>
        </DialogContentText>
        <TextField
          autoFocus
          label={t('region-name')}
          fullWidth
          margin="dense"
          variant="standard"
          value={nameRetyped}
          onChange={(e) => setNameRetyped(e.target.value)}
          error={error}
          helperText={error ? t('del-region-match-warn') : t('del-region-match')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCleanClose}>{t('cancel')}</Button>
        <Button
          onClick={() => {
            setHasBeenClicked(true);
            if (nameRetyped === region.name) {
              RouteService.deleteRegion(region._id)
                .then(({ data }) => {
                  notification.notify(data.message);
                  handleCleanClose();
                  navigate('/dashboard/regions', { replace: true });
                })
                .catch(notification.notify);
            }
          }}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function AllRoutesInRegion() {
  const params = useParams();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [routes, setRoutes] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [loading, setLoading] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRouteDialogOpen, setDeleteRouteDialogOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const notification = useNotification();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (routes.length !== 0 && routes.length >= count) return;
    RouteService.getAllRoutesInRegion(params.id, page + 1, rowsPerPage)
      .then((response) => {
        const { data, count } = response.data;

        console.log('ROUTE DATA: ', data);

        setRoutes((prev) => [...prev, ...data]);
        setCount(count);
      })
      .catch(console.log);

    RouteService.getRegion(params.id)
      .then(({ data: { data } }) => {
        setName(data.name);
      })
      .catch(console.log);
    // eslint-disable-next-line
  }, [params.id, page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = routes.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRoutes([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - routes.length) : 0;

  const filteredRoutes = applySortFilter(routes, getComparator(order, orderBy), filterName);

  const isrouteNotFound = filteredRoutes.length === 0;

  const TABLE_HEAD = [
    // { id: 'locations', label: 'Locations', alignRight: false },
    // { id: 'company', label: 'Company', alignRight: false },
    { id: 'name', label: t('name'), alignRight: false },
    // { id: 'region', label: 'Region(s)', alignRight: false },
    { id: 'routeType', label: t('branch'), alignRight: false },
    // { id: 'startDate', label: 'Start Date', alignRight: false },
    // { id: 'finishDate', label: 'Finish Date', alignRight: false },
    // { id: 'constructionPhase', label: 'Status', alignRight: false },
    { id: '' }
  ];

  return (
    <Page title={name}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          {name ? (
            <>
              <Typography variant="h4" gutterBottom>
                {name}
              </Typography>

              <Button onClick={() => setSettingsOpen(true)}>{t('settings')}</Button>
            </>
          ) : (
            <CircularProgress />
          )}
        </Stack>

        <UpdateRegionDialog
          open={updateDialogOpen}
          setName={setName}
          handleClose={() => setUpdateDialogOpen(false)}
          name={name}
          // open={}
        />

        <SettingsDialog
          setUpdateDialogOpen={setUpdateDialogOpen}
          open={settingsOpen}
          handleClose={() => setSettingsOpen(false)}
          setDeleteDialogOpen={setDeleteDialogOpen}
        />
        <DeleteRegionDialog
          open={deleteDialogOpen}
          handleClose={() => {
            setDeleteDialogOpen(false);
          }}
          region={{ name, _id: params.id }}
        />

        <DeleteDialog
          title={`${t('delete')}?`}
          description={t('irreversible')}
          open={deleteRouteDialogOpen}
          handleClose={() => setDeleteRouteDialogOpen(false)}
          toBeDeleted={toBeDeleted}
          onDelete={(received, setLoading, handleClose) => {
            RouteService.deleteRoute(received)
              .then(({ data }) => {
                setCount((prev) => prev - 1);
                setPage(0);
                setRoutes((prev) => prev.filter((prev) => prev._id !== received));
                setLoading(false);
                notification.notify(data.message);
                handleClose();
              })
              .catch((e) => {
                setLoading(false);
                notification.notify(e);
              });
          }}
        />

        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={6} lg={8}>
            <AppRoutesPercentageRegion region={params.id} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppPinsByPotential region={params.id} />
          </Grid>
        </Grid>

        <Card>
          <CardHeader title={t('routes')} />
          <TableListToolbar
            numSelected={selected.length}
            deleteItems={(e) => console.log(e)}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={routes.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredRoutes.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row) => {
                    const { _id, name, routeType } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, _id)}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            component={RouterLink}
                            to={`/dashboard/routes/details/${_id}`}
                            color="inherit"
                            sx={{ textDecoration: 'none' }}
                            variant="subtitle2"
                          >
                            {name}
                          </Typography>
                        </TableCell>
                        {/* <TableCell align="left">{region}</TableCell> */}
                        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                          {t(routeType)}
                        </TableCell>
                        {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                        <TableCell align="right">
                          <TableMoreMenu
                            viewLink={`/dashboard/routes/details/${_id}`}
                            deleteItem={() => {
                              setToBeDeleted(_id);
                              setDeleteRouteDialogOpen(true);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isrouteNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            labelRowsPerPage={t('rows-per-page')}
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
