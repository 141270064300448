import { useEffect } from 'react';
import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import AllRoutes from './pages/AllRoutes';
import NotFound from './pages/Page404';
import Settings from './pages/Settings';
import ConstructionPins from './pages/ConstructionPins';
import StorePins from './pages/StorePins';
import AuthService from './services/AuthService';
import CreateConstrRoute from './pages/CreateConstrRoute';
import PinDetails from './pages/PinDetails';
import CreateStoreRoute from './pages/CreateStoreRoute';
import UpdateConstructionRoute from './pages/UpdateConstructionRoute';
import UpdateStoreRoute from './pages/UpdateStoreRoute';
import UpdateIndustryRoute from './pages/UpdateIndustryRoute';
import AdminsTable from './pages/AdminsTable';
import WorkersTable from './pages/WorkersTable';
import CreateAdmin from './pages/CreateAdmin';
import CreateWorker from './pages/CreateWorker';
import UpdateWorker from './pages/UpdateWorker';
import UpdateAdmin from './pages/UpdateAdmin';
import IndustryPins from './pages/IndustryPins';
import CreateIndustryRoute from './pages/CreateIndustryRoute';
import Planner from './pages/Planner';
import { PlannerJunction } from './pages/PlannerJunction';
import { isSuperAdmin, isWorker } from './utils/authHelpers';
import WorkerRoutes from './pages/WorkerRoutes';
import AllRegions from './pages/AllRegions';
import AllRoutesInRegion from './pages/AllRoutesInRegion';
import RouteDetails from './pages/RouteDetails';

// ----------------------------------------------------------------------

export default function Router() {
  const loggedIn = useSelector(({ auth }) => auth.loggedIn);
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log('effecting');
  //   console.log('loggedIn: ', loggedIn);
  //   if (loggedIn) {
  //     AuthService.validateToken().catch((e) => {
  //       if (axios.isAxiosError(e) && e.response) {
  //         console.log(e.response);
  //       }
  //     });
  //   } else {
  //     console.log('elsing...');
  //     navigate('/login', { replace: true });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loggedIn, navigate]);

  return useRoutes([
    {
      path: '/dashboard',
      element: loggedIn ? <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'planning', element: <PlannerJunction /> },
        { path: 'planning/view', element: <Planner /> },
        { path: 'planning/view/:id', element: <Planner /> },
        { path: 'settings', element: <Settings /> },
        { path: 'regions', element: <AllRegions /> },
        { path: 'routes-in-region/:id', element: <AllRoutesInRegion /> },
        {
          path: 'routes',
          children: [
            { element: <Navigate to="/routes/all" replace /> },
            { path: 'all', element: !isWorker() ? <AllRoutes /> : <WorkerRoutes /> },
            { path: 'construction', exact: true, element: <ConstructionPins /> },
            { path: 'store', element: <StorePins /> },
            { path: 'industry', element: <IndustryPins /> },
            { path: 'details/:id', element: <RouteDetails /> },
            { path: 'pin/details/:id', element: <PinDetails /> },
            {
              path: 'update',
              children: [
                { path: 'construction/:id', element: <UpdateConstructionRoute /> },
                { path: 'store/:id', element: <UpdateStoreRoute /> },
                { path: 'industry/:id', element: <UpdateIndustryRoute /> }
              ]
            }
          ]
        },
        {
          path: 'users',
          children: [
            {
              path: 'admins',
              element: isSuperAdmin() ? <AdminsTable /> : <Navigate to="/dashboard/app" replace />
            },
            { path: 'workers', element: <WorkersTable /> },
            {
              path: 'new',
              children: [
                { path: 'admin', element: <CreateAdmin /> },
                { path: 'worker', element: <CreateWorker /> }
              ]
            },
            {
              path: 'update',
              children: [
                { path: 'admin/:id', element: <UpdateAdmin /> },
                { path: 'worker/:id', element: <UpdateWorker /> }
              ]
            }
          ]
        }
        // { path: 'products', element: <Products /> },
        // { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: !loggedIn ? <Login /> : <Navigate to="/dashboard" replace /> },
        // { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
