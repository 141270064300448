import {
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Typography,
  Stack,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  List,
  ListSubheader,
  Pagination,
  TablePagination
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { Icon } from '@iconify/react';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import plusFill from '@iconify/icons-eva/plus-fill';
// import deleteFill from '@iconify/icons-eva/person-delete-fill';

import RouteService from '../../services/RouteService';
import { useNotification } from '../../contexts/notification-context';

const NoteDialog = ({ setPage, setCount, setNotes, handleClose, open }) => {
  const { t } = useTranslation();
  const params = useParams();
  const notification = useNotification();

  const formik = useFormik({
    initialValues: { note: '' },
    validationSchema: Yup.object({
      note: Yup.string().required()
    }),
    onSubmit: (values, { setSubmitting }) => {
      RouteService.addNote(values, params.id)
        .then(({ data: resData }) => {
          setSubmitting(false);
          setCount((prev) => prev + 1);
          setPage(0);
          setNotes((prev) => [resData.data, ...prev]);
          notification.notify(resData.message);
          handleClose();
        })
        .catch((e) => {
          setSubmitting(false);
          notification.notify(e);
        });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Dialog open={open} onClose={handleClose}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>{t('add-note')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              multiline
              rows={3}
              {...getFieldProps('note')}
              margin="dense"
              id="note"
              label={t('note')}
              name="note"
              fullWidth
              error={Boolean(touched.note && errors.note)}
              helperText={touched.note && errors.note}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <LoadingButton type="submit" loading={isSubmitting}>
              {t('add-note')}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

const DeleteDialog = ({
  setPage,
  setNotes,
  deletingNote,
  setDeletingNote,
  open,
  handleClose,
  setCount
}) => {
  const params = useParams();
  const notification = useNotification();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('delete-note')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t('irreversible')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('cancel')}</Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            RouteService.deleteNote(deletingNote, params.id)
              .then(({ data: resData }) => {
                setDeletingNote(null);
                setCount((prev) => prev - 1);
                setPage(0);
                setNotes((notes) => notes.filter((note) => note._id !== deletingNote));
                notification.notify(resData.message);
                setLoading(false);
                handleClose();
              })
              .catch((e) => {
                setLoading(false);
                notification.notify(e);
                setDeletingNote(null);
              });
          }}
        >
          {t('delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const Notes = () => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deletingNote, setDeletingNote] = useState(null);
  const [page, setPage] = useState(0);
  const [notes, setNotes] = useState([]);
  const params = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [count, setCount] = useState(0);

  const handleChangeRowsPerPage = (event) => {
    setNotes([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (notes.length !== 0 && notes.length >= count) return;
    RouteService.getNotes(params.id, page + 1, rowsPerPage)
      .then(({ data: resData }) => {
        setNotes((prev) => [...prev, ...resData.data]);
        setCount(resData.count);
      })
      .catch(console.log);
    // eslint-disable-next-line
  }, [page, rowsPerPage, params.id]);

  return (
    <>
      <NoteDialog
        notes={notes}
        setCount={setCount}
        setNotes={setNotes}
        setPage={setPage}
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      />
      <DeleteDialog
        setNotes={setNotes}
        setCount={setCount}
        setPage={setPage}
        setDeletingNote={setDeletingNote}
        handleClose={() => setDeleteOpen(false)}
        deletingNote={deletingNote}
        open={deleteOpen}
      />
      <Card>
        <CardContent>
          <List
            subheader={
              <ListSubheader
                component={Stack}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                id="visits-list-subheader"
              >
                <Typography variant="subtitle2" color="textSecondary">
                  {t('notes')}
                </Typography>
                <IconButton
                  onClick={() => setDialogOpen(true)}
                  aria-label="add-visit"
                  color="primary"
                  edge="end"
                >
                  <Icon icon={plusFill} />
                </IconButton>
              </ListSubheader>
            }
          >
            {notes.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((note) => (
              <ListItem
                key={note._id}
                sx={{ pt: 2, pb: 2 }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => {
                      setDeletingNote(note._id);
                      setDeleteOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={note.text}
                  secondary={`${t('by')} ${
                    note.by ? `${note.by.firstName} ${note.by.lastName}` : `${t('deleted-user')}`
                  }`}
                />
              </ListItem>
            ))}
          </List>
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={(e, value) => setPage(value)}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[3, 6, 9, 12]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default Notes;
