import * as Yup from 'yup';
import { isSuperAdmin } from './authHelpers';
import RouteService from '../services/RouteService';

export const getLocationInitVals = () => {
  const base = {
    name: '',
    address: '',
    postalCode: '',
    coordinates: undefined
  };

  return isSuperAdmin() ? { ...base, region: '' } : base;
};

export const LocationSchema = Yup.object({
  name: Yup.string().required('Location name is required'),
  address: Yup.string().required('Address is required'),
  postalCode: Yup.string().required('Postal code is required'),
  coordinates: Yup.array().of(Yup.number()).optional().max(2).min(2)
});

export const ExtendedConstrSchema = {
  startDate: Yup.date().required(),
  finishDate: Yup.date().required(),
  constructionPhase: Yup.string().required('Construction phase is required'),
  branches: Yup.array()
    .of(Yup.string().oneOf(['E', 'H', 'S', 'MG']))
    .min(1)
    .required(),
  locations: Yup.array().of(
    isSuperAdmin()
      ? LocationSchema.shape({
          region: Yup.string().required('Region is required'),
          major: Yup.boolean().required()
        })
      : LocationSchema.shape({ major: Yup.boolean().required() })
  )
};

export const constrLinearFields = [
  { name: 'startDate', label: 'Start date', comp: 'date' },
  { name: 'finishDate', label: 'Finish date', comp: 'date' },
  { name: 'constructionPhase', label: 'Construction phase', comp: 'select' }
];

export const deleteRoute = (notify, id, setState) => {
  RouteService.deleteRoute(id)
    .then(({ data }) => {
      notify(data.message);
      setState((prev) => prev.filter((route) => route._id !== id));
    })
    .catch((e) => notify(e));
};

export const copyText = async (text) => {
  try {
    navigator.permissions.query({ name: 'clipboard-write' }).then(async (result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        await navigator.clipboard.writeText(text);
      }
    }); /* Resolved - text copied to clipboard successfully */
  } catch (err) {
    console.error('Failed to copy: ', err);
    /* Rejected - text failed to copy to the clipboard */
  }
};

export const mapsAPIKey = 'AIzaSyABvMKM_Oh3uAS-lhqIJEbXQOGNRZPW654';
