import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormik, FormikProvider, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Grid,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  Chip
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { LoadingButton } from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';

import { useNotification } from '../../contexts/notification-context';
import PlaceAutocomplete from '../PlaceAutocomplete';
import { copyText } from '../../utils/routeUtils';
import RouteService from '../../services/RouteService';

PinForm.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  forConstruction: PropTypes.bool,
  initValues: PropTypes.object,
  onSubmit: PropTypes.func
};

export default function PinForm({
  open,
  handleClose,
  title,
  buttonText,
  forConstruction,
  initValues,
  onSubmit
}) {
  const notification = useNotification();
  const { t } = useTranslation();

  const Schema = Yup.object().shape({
    name: Yup.string().required(t('name-loc-req')),
    company: Yup.string().optional(),
    address: Yup.string().optional(),
    city: Yup.string().optional(),
    postalCode: Yup.string().optional(),
    potential: Yup.string().oneOf(['low', 'medium', 'high']).optional(),
    coordinates: Yup.array().of(Yup.number()).min(2).max(2).required(t('coord-req'))
  });

  const baseVals = {
    name: '',
    company: '',
    address: '',
    city: '',
    postalCode: '',
    coordinates: [],
    potential: ''
  };

  // eslint-disable-next-line no-nested-ternary
  const initialValues = !initValues
    ? !forConstruction
      ? baseVals
      : {
          ...baseVals,
          branches: [],
          startDate: new Date(),
          finishDate: new Date(),
          constructionPhase: ''
        }
    : initValues;

  const formik = useFormik({
    initialValues,
    validationSchema: !forConstruction
      ? Schema
      : Schema.shape({
          branches: Yup.array(t('generic-req'))
            .of(Yup.string().oneOf(['E', 'H', 'S', 'MG']))
            .min(0)
            .optional(),
          startDate: Yup.date().required(t('sdate-req')),
          finishDate: Yup.date().required(t('fdate-req')),
          constructionPhase: Yup.string().oneOf(['A', 'B', 'G']).required(t('constr-phase-req'))
        }),
    onSubmit: (values, actions) => {
      onSubmit(values, initialValues, actions);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" component="h2">
                {title}
              </Typography>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                {values.coordinates.length === 2 && (
                  <Tooltip
                    title={`${t('loc-coord')}. ${t('click-copy')}`}
                    onClick={async () => {
                      await copyText(`${values.coordinates[0]}, ${values.coordinates[1]}`);
                      notification.notify(t('copied'));
                    }}
                  >
                    <Chip
                      sx={{ cursor: 'pointer' }}
                      label={`${values.coordinates[0]}, ${values.coordinates[1]}`}
                      variant="outlined"
                      onDelete={() => {
                        setFieldValue('coordinates', []);
                      }}
                    />
                  </Tooltip>
                )}
                {values.coordinates.length !== 2 && (
                  <Stack>
                    <Tooltip
                      open={Boolean(touched.coordinates && errors.coordinates)}
                      title={t('location-warning')}
                    >
                      <Button
                        color={touched.coordinates && errors.coordinates ? 'error' : 'primary'}
                        onClick={() => {
                          if ('geolocation' in navigator) {
                            navigator.geolocation.getCurrentPosition(
                              (position) => {
                                console.log(position);
                                console.log(position.coords.latitude, position.coords.longitude);

                                setFieldValue('coordinates.0', position.coords.latitude);
                                setFieldValue('coordinates.1', position.coords.longitude);
                                RouteService.mapsReverseGeolocate(
                                  position.coords.latitude,
                                  position.coords.longitude
                                )
                                  .then(({ data }) => {
                                    const address = data.results[0]?.formatted_address || '';
                                    const city =
                                      data.results[0]?.address_components.find((c) =>
                                        c.types.includes('locality')
                                      )?.long_name || '';
                                    const postalCode =
                                      data.results[0]?.address_components.find((c) =>
                                        c.types.includes('postal_code')
                                      )?.long_name || '';
                                    setFieldValue('address', address);
                                    setFieldValue('city', city);
                                    setFieldValue('postalCode', postalCode);
                                  })
                                  .catch((e) =>
                                    notification.notify('Could not fetch address details')
                                  );
                              },
                              (e) => {
                                notification.notify(e);
                              },
                              { enableHighAccuracy: true }
                            );
                          } else {
                            alert('Geolocation is not available.');
                          }
                        }}
                      >
                        {t('use-loc')}
                      </Button>
                    </Tooltip>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('name-pin')}
                  fullWidth
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PlaceAutocomplete
                  onCoordinatesValue={(value) => {
                    setFieldValue('coordinates', value);
                    console.log('VALUES IN CALLBACK YO: ', value);
                  }}
                  value={values.address}
                  onInputChange={(value) => setFieldValue('address', value)}
                  onChange={(value) => setFieldValue('address', value)}
                  touched={touched.address}
                  error={errors.address}
                  label={t('address')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('city')}
                  fullWidth
                  {...getFieldProps('city')}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('postal-code')}
                  fullWidth
                  {...getFieldProps('postalCode')}
                  error={Boolean(touched.postalCode && errors.postalCode)}
                  helperText={touched.postalCode && errors.postalCode}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('company')}
                  fullWidth
                  {...getFieldProps('company')}
                  error={Boolean(touched.company && errors.company)}
                  helperText={touched.company && errors.company}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={Boolean(touched.potential && errors.potential)}>
                  <InputLabel id="select-potential">{t('potential')}</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-potential"
                    id={t('potential')}
                    label="Potential"
                    {...getFieldProps('potential')}
                  >
                    {['low', 'medium', 'high'].map((item) => (
                      <MenuItem key={item} value={item}>
                        {sentenceCase(item)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{touched.potential && errors.potential}</FormHelperText>
                </FormControl>
              </Grid>
              {values.startDate && (
                <Grid item xs={12} md={6}>
                  <DatePicker
                    // inputFormat="DD.MM.YYYY"
                    // mask="__.__.____"
                    label={t('start-date')}
                    name="startDate"
                    value={values.startDate}
                    id="startDate"
                    onChange={(value) => setFieldValue('startDate', value)}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
              )}
              {values.finishDate && (
                <Grid item xs={12} md={6}>
                  <DatePicker
                    // inputFormat="DD.MM.YYYY"
                    // mask="__.__.____"
                    label={t('finish-date')}
                    name="finishDate"
                    value={values.finishDate}
                    id="finishDate"
                    onChange={(value) => setFieldValue('finishDate', value)}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
              )}
              {Object.prototype.hasOwnProperty.call(values, 'constructionPhase') && (
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.constructionPhase && errors.constructionPhase)}
                  >
                    <InputLabel id="select-construction-phase">{t('constr-phase')}</InputLabel>
                    <Select
                      fullWidth
                      labelId="select-construction-phase"
                      id="constructionPhase"
                      label={t('constr-phase')}
                      {...getFieldProps('constructionPhase')}
                    >
                      {['A', 'B', 'G'].map((item) => (
                        <MenuItem key={item} value={item}>
                          {sentenceCase(item)}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.constructionPhase && errors.constructionPhase}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
              {forConstruction && (
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="branches"
                    render={(arrayHelpers) => (
                      <Stack
                        style={{ marginLeft: 20 }}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-evenly"
                      >
                        {['E', 'H', 'S', 'MG'].map((branch) => (
                          <FormControlLabel
                            key={branch}
                            label={branch}
                            control={
                              <Checkbox
                                checked={values.branches.includes(branch)}
                                onChange={(e) => {
                                  if (e.target.checked) arrayHelpers.push(branch);
                                  else arrayHelpers.remove(values.branches.indexOf(branch));
                                }}
                              />
                            }
                          />
                        ))}
                      </Stack>
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <LoadingButton loading={isSubmitting} type="submit">
              {buttonText}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
