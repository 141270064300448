import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  FormControl,
  Select,
  FormHelperText,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  IconButton,
  InputAdornment
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { sentenceCase } from 'sentence-case';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import * as Yup from 'yup';
import AuthService from '../../../services/AuthService';
import RouteService from '../../../services/RouteService';
import { pushError } from '../../../slices/error';
import { isSuperAdmin } from '../../../utils/authHelpers';

export default function UserForm({
  marginTop,
  update,
  onFormSubmit,
  initialValues,
  type,
  enableReinitialize
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [regions, setRegions] = useState(null);

  const Schema = Yup.object().shape({
    email: Yup.string().email(t('valid-email')).required(t('email-req')),
    firstName: Yup.string().required(t('fn-req')),
    lastName: Yup.string().required(t('ln-req')),
    password: update ? Yup.string().min(8).optional() : Yup.string().min(8).required(t('pw-req'))
  });

  const fetchRegions = () => {
    if (regions && regions.length > 0) return;
    RouteService.getAllRegions().then(({ data }) => {
      setRegions(data.data.map((dt) => ({ value: dt._id, label: dt.name })));
    });
  };

  useEffect(() => {
    if (regions && regions.length > 0) return;
    RouteService.getAllRegions().then(({ data }) => {
      setRegions(data.data.map((dt) => ({ value: dt._id, label: dt.name })));
    });
  }, [initialValues]);

  const initValues = {
    email: '',
    firstName: '',
    lastName: '',
    password: ''
  };

  const formik = useFormik({
    initialValues: initialValues || (isSuperAdmin() ? { ...initValues, region: '' } : initValues),
    enableReinitialize,
    validationSchema: isSuperAdmin()
      ? Schema.shape({
          region: Yup.string().required(t('reg-req'))
        })
      : Schema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        onFormSubmit(values, setSubmitting);
      } catch (e) {
        console.log('err: ', e);
        dispatch(pushError(e));
      }
    }
  });

  const [showPassword, setShowPassword] = useState(false);

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const labelStarter = `${sentenceCase(type)}'s`;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid sx={marginTop ? { mt: 0.1 } : {}} container spacing={3}>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              label={t('email')}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              label={t('first-name')}
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              label={t('last-name')}
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label={t('password')}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword((show) => !show)} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Grid>
          {isSuperAdmin() && (
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth error={Boolean(touched.region && errors.region)}>
                <InputLabel id="select-region">{t('region')}</InputLabel>
                <Select
                  onOpen={() => {
                    if (!regions) fetchRegions();
                  }}
                  fullWidth
                  labelId="select-region"
                  id="region"
                  label={t('region')}
                  {...getFieldProps('region')}
                >
                  {regions ? (
                    regions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={null}>Loading...</MenuItem>
                  )}
                </Select>
                <FormHelperText>{touched.region && errors.region}</FormHelperText>
              </FormControl>
            </Grid>
          )}
        </Grid>

        <LoadingButton
          sx={{ mt: 3 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {update ? t('update') : t('create')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
