import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Typography,
  Grid,
  Box,
  // Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  Button
  // FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import AuthService from '../../services/AuthService';
import { updateProfile } from '../../slices/auth/actions';
import getChangedValues from '../../utils/getChangedValues';
// import { useNotification } from '../../contexts/notification-context';

const UpdateSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required')
});

export default function Profile() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const notification = useNotification();

  const [showPassword, setShowPassword] = useState(false);
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: '',
    lastName: '',
    firstName: ''
  });

  useEffect(() => {
    AuthService.getProfile()
      .then(({ data: { data } }) => {
        setInitialValues(data);
      })
      .catch(console.log);
  }, []);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: UpdateSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(updateProfile(getChangedValues(values, initialValues))).then(() => {
        setSubmitting(false);
      });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Box>
      <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h6">{t('profile-set')}</Typography>
          <Typography variant="body2" color="textSecondary">
            {t('update-profile')}
          </Typography>
        </Box>
        <Button onClick={() => setEnableUpdate(true)}>{t('update')}</Button>
      </Stack>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!enableUpdate}
                type="email"
                label={t('email')}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!enableUpdate}
                label={t('first-name')}
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!enableUpdate}
                label={t('last-name')}
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Grid>
          </Grid>

          {enableUpdate && (
            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              {t('update')}
            </LoadingButton>
          )}
        </Form>
      </FormikProvider>
    </Box>
  );
}
