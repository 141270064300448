import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Typography,
  Grid,
  Box,
  // Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  Button
  // FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import AuthService from '../../services/AuthService';
import { updateProfile } from '../../slices/auth/actions';
import getChangedValues from '../../utils/getChangedValues';
import { useNotification } from '../../contexts/notification-context';

const UpdateSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current Password is required'),
  password: Yup.string().min(8).required('Password is required')
});

export default function UpdatePassword() {
  const { t } = useTranslation();
  const notification = useNotification();
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      password: ''
    },
    enableReinitialize: true,
    validationSchema: UpdateSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      AuthService.updatePassword(values)
        .then(({ data }) => {
          setSubmitting(false);
          notification.notify(data.message);
          resetForm();
        })
        .catch((e) => {
          setSubmitting(false);
          notification.notify(e);
        });
    }
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Box>
      <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h6">{t('change-pw')}</Typography>
        </Box>
      </Stack>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type={showCurrentPassword ? 'text' : 'password'}
                label={t('current-pw')}
                {...getFieldProps('currentPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowCurrentPassword((show) => !show)}
                        edge="end"
                      >
                        <Icon icon={showCurrentPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.currentPassword && errors.currentPassword)}
                helperText={touched.currentPassword && errors.currentPassword}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label={t('new-pw')}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword((show) => !show)} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Grid>
          </Grid>

          <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
            {t('change-pw')}
          </LoadingButton>
        </Form>
      </FormikProvider>
    </Box>
  );
}
