import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Button,
  ListItemText,
  CircularProgress,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  Stack,
  Typography
} from '@mui/material';

import AuthService from '../../services/AuthService';
import { useNotification } from '../../contexts/notification-context';

const Centered = ({ children }) => (
  <Stack justifyContent="center" alignItems="center" sx={{ width: 200, height: 200 }}>
    {children}
  </Stack>
);

export default function SelectWorkersList({
  open,
  onClose,
  handleSelect,
  fromRegion,
  filterFromList
}) {
  const [workers, setWorkers] = useState([]);
  const [checked, setChecked] = useState([]);
  const [loading, setLoading] = useState(false);

  const notification = useNotification();
  const { t } = useTranslation();

  console.log('checked: ', checked);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const cleanClose = () => {
    setChecked([]);
    onClose();
  };

  useEffect(() => {
    console.log('EFFECTING');
    const handler = ({ data: { data } }) => {
      console.log('DATA: ', data);
      setWorkers(
        filterFromList
          ? data.filter((worker) => !filterFromList.find((id) => id === worker._id))
          : data
      );
      setLoading(false);
    };

    const errorHandler = (e) => {
      setLoading(false);
      // notification.notify(e);
    };

    console.log('OPEN: ', open);

    if (!open) return;
    setLoading(true);
    if (fromRegion) {
      console.log('FROM REGION');
      AuthService.getWorkersFromRegion(fromRegion).then(handler).catch(errorHandler);
    } else {
      console.log('not from region');
      AuthService.getAllUsers().then(handler).catch(errorHandler);
    }
  }, [open, fromRegion, filterFromList, notification]);

  return (
    <Dialog onClose={onClose} open={open}>
      {!loading ? (
        <>
          {workers.length > 0 ? (
            <>
              <DialogTitle>{t('select-worker')}</DialogTitle>
              <DialogContent>
                <List sx={{ width: '100%' }}>
                  {workers.map((worker, index) => {
                    const labelId = `checkbox-list-label-${worker._id}`;
                    return (
                      <ListItem key={worker._id} disablePadding>
                        <ListItemButton role={undefined} onClick={handleToggle(worker._id)} dense>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(worker._id) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={`${worker.firstName} ${worker.lastName}`}
                            secondary={worker.email}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </DialogContent>

              <DialogActions>
                <Button onClick={cleanClose}>{t('cancel')}</Button>
                <Button
                  onClick={() => {
                    handleSelect(checked);
                    cleanClose();
                  }}
                >
                  {t('ok')}
                </Button>
              </DialogActions>
            </>
          ) : (
            <Centered>
              <Typography variant="body1" textAlign="center" color="textSecondary">
                {t('no-worker')}
              </Typography>
            </Centered>
          )}
        </>
      ) : (
        <Centered>
          <CircularProgress />
        </Centered>
      )}
    </Dialog>
  );
}
