import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import UserForm from '../components/authentication/register/UserForm';
import Page from '../components/Page';
import AuthService from '../services/AuthService';
import { useNotification } from '../contexts/notification-context';

export default function CreateWorker() {
  const navigate = useNavigate();
  const notification = useNotification();
  const { t } = useTranslation();

  return (
    <Page title={t('create-worker')}>
      <Container>
        <Typography variant="h4" gutterBottom mb={5}>
          {t('create-worker')}
        </Typography>
        <UserForm
          type="worker"
          onFormSubmit={(values, setSubmitting) => {
            AuthService.createWorker(values)
              .then(({ data }) => {
                setSubmitting(false);
                notification.notify(data.message);
                navigate('/dashboard/users/workers', { replace: true });
              })
              .catch((e) => {
                setSubmitting(false);
                notification.notify(e);
              });
          }}
        />
      </Container>
    </Page>
  );
}
