import _ from 'lodash';
import {
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  IconButton,
  Stack
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DraggableList from '../draggable-list/DraggableList';
import reorder from '../../utils/reorder';
import RouteService from '../../services/RouteService';
import { useNotification } from '../../contexts/notification-context';
import { isSuperAdmin, isAdmin } from '../../utils/authHelpers';
import { pinPropType } from '../draggable-list/itemPropType';

// <List
// subheader={
//   <ListSubheader
//     component={Stack}
//     direction="row"
//     justifyContent="space-between"
//     alignItems="center"
//     id="nested-list-subheader"
//   >
//     {t('locations')}
//     <Button onClick={() => setPinDialogOpen(true)}>{t('create-location')}</Button>
//   </ListSubheader>
// }
// >

PinList.propTypes = {
  pins: PropTypes.arrayOf(pinPropType).isRequired,
  setRoute: PropTypes.func,
  setPinDialogOpen: PropTypes.func,
  setPinDeleteDialogOpen: PropTypes.func,
  setToBeDeleted: PropTypes.func
};

export default function PinList({
  pins,
  setRoute,
  setPinDialogOpen,
  setPinDeleteDialogOpen,
  setToBeDeleted
}) {
  const params = useParams();
  const notification = useNotification();
  const { t } = useTranslation();

  const isAnAdmin = isSuperAdmin() || isAdmin();

  return isAnAdmin ? (
    <DraggableList
      pins={pins}
      setDialogOpen={setPinDialogOpen}
      onDragEnd={({ destination, source }) => {
        if (!destination) return;

        let reorderedIds;
        setRoute((prev) => {
          const reordered = reorder(prev.pins, source.index, destination.index);
          reorderedIds = reordered.map((pin) => _.pick(pin, ['_id'])._id);
          return {
            ...prev,
            pins: reordered
          };
        });

        RouteService.rearrangePins(params.id, reorderedIds)
          .then(() => notification.notify(t('loc-rearranged')))
          .catch(notification.notify);
      }}
      setDeleteDialogOpen={setPinDeleteDialogOpen}
      setToBeDeleted={setToBeDeleted}
    />
  ) : (
    <List
      subheader={
        <ListSubheader
          component={Stack}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          id="nested-list-subheader"
        >
          {t('locations')}
          <Button onClick={() => setPinDialogOpen(true)}>{t('create-location')}</Button>
        </ListSubheader>
      }
    >
      {pins.map((pin) => (
        <ListItem
          key={pin._id}
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => {
                setPinDeleteDialogOpen(true);
                setToBeDeleted(pin._id);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          }
          disablePadding
        >
          <ListItemButton component={Link} to={`/dashboard/routes/pin/details/${pin._id}`}>
            <ListItemIcon>
              <LocationOnIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={pin.name}
              secondary={`${pin.address || t('none')}, ${pin.city || t('none')}`}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
