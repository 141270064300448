import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Typography, Container, CircularProgress } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Page from '../components/Page';
import AuthService from '../services/AuthService';
import CreateUserForm from '../components/authentication/register/UserForm';
import getChangedValues from '../utils/getChangedValues';
import { pushError } from '../slices/error';
import { useNotification } from '../contexts/notification-context';

export default function UpdateAdmin() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const notification = useNotification();

  const [initValues, setInitValues] = useState(null);

  useEffect(() => {
    AuthService.getAdminFromId(params.id).then(({ data: { data } }) => {
      const cleanedData = { ...data, password: '' };

      delete cleanedData._id;
      delete cleanedData.__v;

      setInitValues(cleanedData);
    });
  }, [params.id, navigate]);

  return (
    <Page title={t('update-admin')}>
      <Container>
        <Typography variant="h4" gutterBottom mb={5}>
          {t('update-admin')}
        </Typography>
        {initValues ? (
          <CreateUserForm
            initialValues={initValues}
            type="admin"
            update
            enableReinitialize
            onFormSubmit={(values, setSubmitting) => {
              AuthService.updateAdmin(params.id, getChangedValues(values, initValues))
                .then(({ data }) => {
                  setSubmitting(false);
                  notification.notify(data.message);
                  navigate('/dashboard/users/admins', { replace: true });
                })
                .catch((e) => {
                  setSubmitting(false);
                  notification.notify(e);
                });
            }}
          />
        ) : (
          <CircularProgress />
        )}
      </Container>
    </Page>
  );
}
