import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  ListItemButton,
  ListItemText,
  List,
  ListSubheader,
  Card,
  IconButton,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  ListItem,
  Stack,
  TablePagination,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

import { useNotification } from '../../contexts/notification-context';
import RouteService from '../../services/RouteService';
import DeleteDialog from '../DeleteDialog';
import { isSuperAdmin } from '../../utils/authHelpers';

const AddVisitDialog = ({ setPage, setCount, setVisits, open, handleClose }) => {
  const { t, i18n } = useTranslation();
  const notification = useNotification();
  const params = useParams();

  const Schema = Yup.object().shape({
    date: Yup.date().required(t('generic-req')),
    note: Yup.string().required(t('generic-req')),
    newMembers: Yup.number().optional()
  });

  const formik = useFormik({
    initialValues: {
      date: new Date(),
      note: '',
      newMembers: 0
    },
    validationSchema: Schema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      RouteService.addVisit(values, params.id)
        .then(({ data: resData }) => {
          setSubmitting(false);
          setCount((prev) => prev + 1);
          setPage(0);
          setVisits((prev) => [resData.data, ...prev]);

          resetForm();
          notification.notify(resData.message);

          handleClose();
        })
        .catch((e) => {
          notification.notify(e);
          setSubmitting(false);
        });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{t('add-visit')}</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={2}>
              {isSuperAdmin() && (
                <DateTimePicker
                  // inputFormat="DD.MM.YYYY HH:mm:ss"
                  // mask="__.__.____"
                  label={t('date-of-visit')}
                  name="date"
                  value={values.date}
                  id="date"
                  onChange={(value) => setFieldValue('date', value)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              )}
              <TextField
                label={t('note')}
                fullWidth
                {...getFieldProps('note')}
                error={Boolean(touched.note && errors.note)}
                helperText={touched.note && errors.note}
              />

              <Typography variant="subtitle2" color="textSecondary">
                {t('new-members')}
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="new-members-group-label"
                  // {...getFieldProps('newMembers')}
                  value={values.newMembers}
                  onChange={(e) => {
                    setFieldValue('newMembers', Number(e.target.value));
                  }}
                >
                  <Stack direction="row">
                    <FormControlLabel value={0} control={<Radio />} label={t('none')} />
                    <FormControlLabel value={1} control={<Radio />} label="1" />
                    <FormControlLabel value={2} control={<Radio />} label="2" />
                    <FormControlLabel value={3} control={<Radio />} label="3" />
                    <FormControlLabel value={4} control={<Radio />} label="4" />
                    <FormControlLabel value={5} control={<Radio />} label="5" />
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <LoadingButton loading={isSubmitting} type="submit">
              {t('add')}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default function Visits() {
  const [addVisitDialogOpen, setAddVisitDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [visits, setVisits] = useState([]);
  const [page, setPage] = useState(0);
  const [pagesNecessary, setPagesNecessary] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [count, setCount] = useState(0);

  const { t } = useTranslation();

  const notification = useNotification();
  const params = useParams();

  const handleChangeRowsPerPage = (event) => {
    setVisits([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (visits.length !== 0 && visits.length >= count) return;
    RouteService.getVisits(params.id, page + 1, rowsPerPage)
      .then(({ data: resData }) => {
        setVisits((prev) => [...prev, ...resData.data]);
        setCount(resData.count);
      })
      .catch(console.log);
    // eslint-disable-next-line
  }, [page, rowsPerPage, params.id]);

  return (
    <>
      <DeleteDialog
        title={t('delete-visit')}
        description={t('irreversible')}
        open={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        toBeDeleted={toBeDeleted}
        onDelete={(received, setLoading, handleClose) => {
          RouteService.deleteVisit(received, params.id)
            .then(({ data }) => {
              notification.notify(data.message);
              setCount((prev) => prev - 1);
              setPage(0);
              setVisits((prev) => prev.filter((visit) => visit._id !== received));
              setLoading(false);
              handleClose();
            })
            .catch((e) => {
              setLoading(false);
              notification.notify(e);
            });
        }}
      />
      <AddVisitDialog
        setPage={setPage}
        setCount={setCount}
        visits={visits}
        open={addVisitDialogOpen}
        setVisits={setVisits}
        handleClose={() => setAddVisitDialogOpen(false)}
      />
      <Card>
        <CardContent>
          <List
            subheader={
              <ListSubheader
                component={Stack}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                id="visits-list-subheader"
              >
                {t('visits')}
                <IconButton
                  onClick={() => setAddVisitDialogOpen(true)}
                  aria-label="add-visit"
                  color="primary"
                  edge="end"
                >
                  <Icon icon={plusFill} />
                </IconButton>
              </ListSubheader>
            }
          >
            {visits.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((visit) => (
              <ListItem
                key={visit._id}
                disablePadding
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => {
                      setToBeDeleted(visit._id);
                      setDeleteDialogOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemButton sx={{ pt: 2, pb: 2 }}>
                  <ListItemText
                    primary={visit.note || ''}
                    secondary={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 0.5,
                          display: 'flex',
                          alignItems: 'center',
                          color: 'text.disabled',
                          width: '100%'
                        }}
                      >
                        {`${t('visit-on')} ${new Date(visit.date).toLocaleString()} ${t('by-user', {
                          user: visit.by
                            ? `${visit.by.firstName} ${visit.by.lastName}`
                            : t('deleted-user')
                        })}`}
                        <br />
                        {`${t('new-members')}: ${visit.newMembers || 0}`}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={(e, value) => setPage(value)}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[3, 6, 9, 12]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
    </>
  );
}
