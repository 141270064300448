import { createSlice } from '@reduxjs/toolkit';
import { serializeError } from 'serialize-error';

const initialState = [];

function isRejectedAction(action) {
  return action.type.endsWith('rejected');
}

const errorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    pushError: (state, action) => {
      state.push(action.payload instanceof Error ? serializeError(action.payload) : action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.push(action.error);
    });
  }
});

export const { pushError } = errorSlice.actions;
export default errorSlice.reducer;
