import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getMessaging, getToken } from 'firebase/messaging';
import { sentenceCase } from 'change-case';
import {
  Box,
  Backdrop,
  CircularProgress,
  Stack,
  Button,
  Typography,
  Container,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  Select
} from '@mui/material';
import { DateTimePicker } from '@mui/lab';
import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  DayView,
  MonthView,
  Appointments,
  AppointmentTooltip,
  AllDayPanel,
  Toolbar,
  ViewSwitcher,
  ConfirmationDialog,
  AppointmentForm,
  DateNavigator
  // TodayButton
} from '@devexpress/dx-react-scheduler-material-ui';
import { useTheme } from '@mui/material/styles';

import Page from '../components/Page';
import ScheduleService from '../services/ScheduleService';
import { useNotification } from '../contexts/notification-context';
import { vapidConfig } from '../utils/firebaseUtils';

const currentDate = '2021-11-13';
const schedulerData = [
  { startDate: '2018-11-01T09:45', endDate: '2018-11-01T11:00', title: 'Meeting' },
  { startDate: '2018-11-01T12:00', endDate: '2018-11-01T13:30', title: 'Go to a gym' }
];

const Appointment = ({ children, style, ...restProps }) => {
  const theme = useTheme();
  return (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '8px'
      }}
    >
      {children}
    </Appointments.Appointment>
  );
};

const Switcher = ({ currentView, availableViews, onChange }) => {
  const { t } = useTranslation();
  return (
    // <FormControl>
    // {/* <InputLabel id="view-switcher-label">View</InputLabel> */}
    <Select
      sx={{ mb: 1 }}
      labelId="view-switcher-label"
      id="view-switcher"
      value={currentView.name}
      label="View"
      onChange={(e) => onChange(e.target.value)}
    >
      {availableViews.map((view) => (
        <MenuItem key={view.name} value={view.name}>
          {t(view.name.toLowerCase())}
        </MenuItem>
      ))}
    </Select>
    // </FormControl>
  );
};

const languageMapper = {
  en: 'en-UK',
  de: 'de-GR',
  fr: 'fr-FR'
};

// export function CommandButton({ id, onExecute, children, ...restProps }) {
//   const theme = useTheme();

//   return (
//     <Button id={id} onExecute={onExecute} {...restProps}>
//       {children}
//     </Button>
//   );
// }

// const CommandLayout = ({ children, ...restProps }) => (
//   <AppointmentForm.CommandLayout {...restProps} commandButtonComponent={CommandButton}>
//     {children}
//   </AppointmentForm.CommandLayout>
// );

// const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
//   const onCustomFieldChange = (nextValue) => {
//     onFieldChange({ customField: nextValue });
//   };

//   return (
//     <AppointmentForm.BasicLayout
//       appointmentData={appointmentData}
//       onFieldChange={onFieldChange}
//       {...restProps}
//     >
//       <AppointmentForm.Label text="Custom Field" type="title" />
//       <AppointmentForm.TextEditor
//         value={appointmentData.customField}
//         onValueChange={onCustomFieldChange}
//         placeholder="Custom field"
//       />
//     </AppointmentForm.BasicLayout>
//   );
// };

// messaging.getToken(vapidConfig);

const DateEditor = ({ value, onValueChange }) => (
  <DateTimePicker
    // inputFormat="DD.MM.YYYY HH:mm:ss"
    // mask="__.__.____"
    // label={t('date-of-visit')}
    name="date"
    value={value}
    id="date"
    onChange={(dateValue) => onValueChange(dateValue)}
    renderInput={(params) => <TextField sx={{ mt: 2 }} {...params} />}
  />
);

export default function Planner() {
  const { t, i18n } = useTranslation();
  const messaging = getMessaging();
  const [fetching, setFetching] = useState(false);
  const [user, setUser] = useState(null);
  const [data, setData] = useState([]);
  const [token, setToken] = useState(null);
  const notification = useNotification();

  const params = useParams();

  useEffect(() => {
    getToken(messaging, { ...vapidConfig })
      .then((currentToken) => {
        if (currentToken) {
          setToken(currentToken);
          console.log('current token for client: ', currentToken);

          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log('No registration token available. Request permission to generate one.');

          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      });
  }, [messaging]);

  useEffect(() => {
    setFetching(true);
    if (!params.id) {
      ScheduleService.getSchedules()
        .then(({ data: { data } }) => {
          setData(data.map((dt) => ({ ...dt, id: dt._id })));
          setFetching(false);
        })
        .catch(console.log);
    } else {
      ScheduleService.getSchedulesForUser(params.id)
        .then(({ data: { data } }) => {
          setUser(data.user);
          setData(data.schedules.map((dt) => ({ ...dt, id: dt._id })));
          setFetching(false);
        })
        .catch(console.log);
    }
  }, [params]);

  const commitChanges = ({ added, changed, deleted }) => {
    console.log('added: ', added);
    if (added) {
      const handler = ({ data: reqData }) => {
        const { data } = reqData;
        setData((prev) => [...prev, { ...data, id: data._id }]);

        notification.notify(reqData.message);
      };

      if (!user) {
        ScheduleService.createSchedule(added, token)
          .then(handler)
          .catch((e) => notification.notify(e));
      } else {
        ScheduleService.createScheduleForUser(added, user._id)
          .then(handler)
          .catch((e) => notification.notify(e));
      }
    }

    if (changed) {
      Promise.all(
        Object.entries(changed).map(([id, changedValues]) =>
          !user
            ? ScheduleService.updateSchedule(id, changedValues, token)
            : ScheduleService.updateScheduleForUser(id, user._id, changedValues)
        )
      )
        .then((responses) => {
          setData((prev) => {
            const capture = [...prev];

            responses.forEach(({ data: reqData }) => {
              const { data } = reqData;
              const prevValue = capture.find((appointment) => appointment.id === data._id);
              if (prevValue)
                capture.splice(capture.indexOf(prevValue), 1, { ...data, id: data._id });

              notification.notify(reqData.message);
            });

            return capture;
          });
        })
        .catch(notification.notify);
    }

    if (deleted !== undefined) {
      const handler = ({ data: reqData }) => {
        notification.notify(reqData.message);
        setData((prev) => prev.filter((appointment) => appointment.id !== deleted));
      };

      if (!user) {
        ScheduleService.deleteSchedule(deleted).then(handler).catch(notification.notify);
      } else {
        ScheduleService.deleteScheduleForUser(deleted, user._id)
          .then(handler)
          .catch(notification.notify);
      }
    }
  };

  return (
    <Page title={t('schedule')}>
      {fetching ? (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box>
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Stack spacing={1}>
                <Typography variant="h4">
                  {!user
                    ? t('schedule')
                    : t('schedule-for-user', { user: `${user.firstName} ${user.lastName}` })}
                </Typography>
                {user && (
                  <Typography
                    sx={{ textTransform: 'capitalize' }}
                    variant="caption"
                    color="textSecondary"
                  >
                    {t(user.roles[user.roles.length - 1].toLowerCase())}
                  </Typography>
                )}
              </Stack>
              {user && (
                <Stack spacing={1}>
                  <Typography variant="caption" color="textSecondary">
                    {t('email')}: {user.email}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {t('region')}: {user.region.name}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Container>

          <Paper>
            <Scheduler data={data} locale={languageMapper[i18n.language]}>
              <ViewState defaultCurrentViewName="Month" />
              <EditingState onCommitChanges={commitChanges} />
              <IntegratedEditing />
              <WeekView startDayHour={0} endDayHour={24} />
              <DayView startDayHour={0} endDayHour={24} />
              <MonthView />
              <Toolbar />
              <ViewSwitcher switcherComponent={Switcher} />
              <DateNavigator />
              {/* <TodayButton /> */}
              <Appointments appointmentComponent={Appointment} />
              <AppointmentTooltip
                showCloseButton
                showOpenButton
                contentComponent={(props) => (
                  <AppointmentTooltip.Content {...props}>
                    <Box sx={{ py: 1, px: 2 }}>
                      <Typography>{props.appointmentData.notes}</Typography>
                    </Box>
                  </AppointmentTooltip.Content>
                )}
              />
              <ConfirmationDialog
                messages={{
                  discardButton: t('discard'),
                  cancelButton: t('cancel'),
                  confirmCancelMessage: t('discard-msg'),
                  confirmDeleteMessage: t('delete-apmt-msg'),
                  deleteButton: t('delete')
                }}
              />
              <AppointmentForm
                locale={languageMapper[i18n.language]}
                messages={{
                  allDayLabel: t('all-day'),
                  titleLabel: t('title'),
                  detailsLabel: t('details'),
                  moreInformationLabel: t('more-info'),
                  notesLabel: t('notes'),
                  commitCommand: t('save')
                }}
                dateEditorComponent={DateEditor}
              />
              <AllDayPanel />
            </Scheduler>
          </Paper>
        </Box>
      )}
    </Page>
  );
}
