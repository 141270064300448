import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogActions,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function DeleteDialog({
  title,
  description,
  toBeDeleted,
  onDelete,
  open,
  handleClose
}) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('cancel')}</Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            setLoading(true);
            onDelete(toBeDeleted, setLoading, handleClose);
          }}
        >
          {t('delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
