import axios from 'axios';
// import { pushNotification } from '../slices/notification';

export const apiErrorParser = (e) => {
  if (axios.isAxiosError(e) && e.response) {
    const error = new Error(e.response.data.message);
    error.status = e.response.status;

    throw error;
  } else {
    throw e;
  }
};

export const commonSuccessRespFilter = (response) => {
  console.log('data: ', response.data);
  if (!response.data.success) throw new Error(response.data.message);

  return response;
};
